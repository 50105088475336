import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Masonry from 'react-masonry-css'
 
import { useNavigate, useParams } from 'react-router-dom'
import {TITIK_BACA_CONTENT_CATEGORIES, TITIK_BACA, TITIK_BACA_GET_THEMES, TITIK_BACA_REKOMENDASI, TITIK_BACA_TERLARIS, TITIK_BACA_TOP, TITIK_BACA_BERITA, TITIK_BACA_GET_RSS, TITIK_BACA_YOUTUBE, GET_RADIO, TITIK_BACA_KATEGORI, BASE_URL } from '../api'
import { shuffle, executeGet, executePost, createNewHeaders, formatTanggal } from '../functions/Kubuku'
import Glider from 'react-glider'
import 'glider-js/glider.min.css'
import SkeletonHome from '../components/SkeletonHome'
import logo_titikbaca from '../assets/images/logo-titikbaca.png'
import QRCode from "react-qr-code";

import image_location from '../assets/images/location.png'
import logoLiterasiKeluarga from "../assets/images/logo_literasikeluarga.png";
import iconIlmuTerapan from "../assets/category-icon/ic-terapan.svg";
import iconKesehatan from "../assets/category-icon/ic-kesehatan.svg";
import iconKementrian from "../assets/category-icon/ic-kementrian-lembaga.svg";
import iconUmum from "../assets/category-icon/ic-koleksi-umum.svg";
import iconAudiobook from "../assets/category-icon/ic-audiobooks.svg";
import iconKemenag from "../assets/category-icon/ic-kemenag.svg";
import iconLokal from "../assets/category-icon/ic-pl.svg";
import iconVideobook from "../assets/category-icon/ic-videobooks.svg";
import iconKemendikbud from "../assets/category-icon/ic-kemendikbud.svg";
import iconLiterasiDaerah from "../assets/category-icon/ic-literasi-daerahs.svg";
import iconSMP from "../assets/category-icon/smp.svg";
import iconGames from "../assets/category-icon/game_fam.svg";
import iconBukuAjar from "../assets/category-icon/ic-buku-ajar-sekolah.svg";
import iconBukuPengayaan from "../assets/category-icon/ic-buku-pengayaan.svg";
import iconP3SMPT from "../assets/category-icon/ic-p3smpt.svg";
import iconInfoKemendikbud from "../assets/category-icon/ic-info-kemendikbud.svg";
import iconInfoSekolah from "../assets/category-icon/ic-info-sekolah.svg";
import iconPermainanEdukasi from "../assets/category-icon/ic-permainan-deukasi.svg";
import iconContenCreator from "../assets/category-icon/content_creator.png";
import mascot from "../assets/images/mascot.gif"
import musik from "../assets/images/musik.png";
import kreator from "../assets/images/kreator.png";
import kreator_s from "../assets/images/kreator_s.png";
import paprika from "../assets/images/paprika.png";
import qrwifi from "../assets/images/wifi.jpeg";
import Draggable from 'react-draggable';


import ContentJauh from '../components/ContenJauh'
import ContentNoGPS from '../components/ContentNoGPS'
var warna = ['bg-purple', 'bg-gold', 'bg-blue', 'bg-tosca', 'bg-red', 'bg-green']

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isModal: false, //popup loading
      showMain: false,
      param: this.props.params,
      dataterdekat: '',
      dataterdekat_array: [],
      data: '',
      news: [],
      mode: 1,
      hal: 1,
      dataRekomendasi: '',
      dataTerlaris: '',
      dataTop: [],
      dataBerita: '',
      datakategori: '',
      youtube: [],
      dataRadio: [],
      flagRadio: false,
      flagData: false,
      flagBerita: false,
      flagDataRSS: false,
      dataRSS: [],
      halRSS: 2,
      dataRssKominfo: [],
      flagRSSKominfo: false,
      flagRekomendasi: false,
      flagYoutube: false,
      flagDataTop: false,
      showModal: false, //popup preview
      alias: 'kfh-12f9-kas',
      lokasi: '',
      lat: '-7.557130246009252',
      long: '110.79448368501073',
      alt: '1',
      jauh: false,
      msgJauh: '',
      lokasiMsgJauh: '',
      halaman: 1,
      noGPS: false,
      flagMoreSearch: false,
      urlTitikBaca: "",
      hasMore: true,
      msgTitleGPS: 'Aktifkan Servis Lokasi',
      msgGPS: 'Tidak dapat mendeteksi lokasi anda, pastikan GPS anda aktif, dan mengijinkan browser anda membaca lokasi anda',
      color: 'bg-orange',
      darkcolor: 'bg-orang-dark',
      textcolor: 'text-orange',
      imgHead: '',
      imgSplash:'',
      flagAudio: false,
      dataAudio: [],
      flagVideo: false,
      dataVideo: [],
      flagKesehatan: false,
      dataKesehatan: [],
      hybrid_umum: true,
      hybrid_smp:false,
      hybrid_sma:false,
      sn:'',
      guid: ''
    }
  }

  componentDidMount = async () => {
    if (window.localStorage.getItem('uuid') == null) {
      let uuid = uuidv4()
      window.localStorage.setItem('uuid', uuid)
    }
    let al = String(this.props.params.alias)
    let pushal = "kfh-12f9-kas";
    window.localStorage.setItem('color', 'bg-orange')
    console.log("ALLLL " + al);
    if (al === 'home' || al === 'undefined') {
      if (!window.localStorage.getItem('alias') || window.localStorage.getItem('alias') == 'undefined' || window.localStorage.getItem('alias') == 'tidakadatitik') {
        this.setState({
          alias: 'kfh-12f9-kas',
          lokasi: 'Selamat Datang',
          urlTitikBaca: window.location.origin + '/kfh-12f9-kas'
        })
        window.localStorage.setItem('nama', 'Selamat Datang')
        //this.getTitikBacaTerdekat()
      } else {
        
        this.setState({
          alias: window.localStorage.getItem('alias'),
          //urlTitikBaca: window.location.origin + '/' + window.localStorage.getItem('alias')
          urlTitikBaca: 'https://titikbaca.perpusnas.go.id/' + window.localStorage.getItem('alias')
        });
        pushal = window.localStorage.getItem('alias');
        
        pushal = this.props.params.alias;
      }
    } else {
      console.log('DISINI ' + this.props.params.alias)
      this.setState({
        alias: this.props.params.alias,
        urlTitikBaca: 'https://titikbaca.perpusnas.go.id/' + this.props.params.alias
        //urlTitikBaca: window.location.origin + '/' + this.props.params.alias
      });
      pushal = this.props.params.alias;
      window.localStorage.setItem('alias', al)
    }
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const sn = params.get('sn');
    const guid = params.get('guid');
    if (sn!=null && sn!='undefined' && guid!='undefined'  && guid!=null) {
      window.localStorage.setItem('sn', sn);
      window.localStorage.setItem('guid', guid);
      this.setState({
        sn: sn,
        guid: guid
      })
    }
    console.log("PUSHALL " + pushal);
    window.localStorage.setItem('alias', pushal);
    this.getLocation()

    warna = shuffle(warna)
  }
  getTheme = async () => {
    try {
      //const json = await executeGet(TITIK_BACA_GET_THEMES + alias);
      if (window.localStorage.getItem('themes') != null) {
        const ljson = window.localStorage.getItem('themes');
        const json = JSON.parse(ljson)
        if (json.code == 200) {
          if (json.warna!="") {
            this.setState({
              color: json.warna
            });
          }
          if (json.dark!="") {
            this.setState({
              darkcolor: json.dark
            });
          }
          if (json.text!="") {
            this.setState({
              textcolor: json.text
            });
          }
          if (json.head!="") {
            this.setState({
              imgHead: json.head
            });
          }
          if (json.splash!="") {
            this.setState({
              imgSplash: json.splash
            });
          }
        }
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }
  getLocation = async () => {
    if (window.localStorage.getItem("sn")!=null) {
      let url = "https://kubuku.id/api/titikBaca/getTitikBacaGPS/" + window.localStorage.getItem("sn") + "/" + window.localStorage.getItem("guid") + "/" + window.localStorage.getItem("alias");
      console.log(url);

      const json = await executeGet(url)
      console.log(json);
      if (json.code=="200") {

        console.log("SN NYA: " + window.localStorage.getItem("sn"))
        //this.getTheme();
        console.log("KOORDINAT: " + json.lat + ' -- ' + json.long + ' --- ' + json.alt)
        window.localStorage.setItem('alt', json.alt);
        window.localStorage.setItem('long', json.long);
        window.localStorage.setItem('lat', json.lat);
        this.getBukuPopuler(json.lat, json.long, json.alt)
      } else {
        window.localStorage.removeItem("sn");
        window.localStorage.removeItem("guid");
        this.geolocFail();
      }
    } else {
      if (navigator.geolocation) {
        var location_timeout = setTimeout('this.geolocFail()', 30000)

        navigator.geolocation.getCurrentPosition(
          (position) => {
            clearTimeout(location_timeout);
            let long = position.coords.longitude
            let lat = position.coords.latitude;
            let alt = position.coords.altitude;
            this.setState({
              lat: lat,
              long: long,
              alt: alt
            })
            this.getTheme();
            this.getBukuPopuler(lat, long, alt)
            
          },
          (error) => {
            clearTimeout(location_timeout)
            this.geolocFail()
          }
        )
      } else {
        this.geolocFail()
      }
    }
  }

  geolocFail = () => {
    this.setState({
      noGPS: true,
      isLoading: false
    })

  }

  aktifkanGeo = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        //alert("pos");
      },
      (error) => {
        //alert(error.message);
      }
    )
  }
  getBukuPopuler = async (lat, long, alt) => {
    try {
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt);
      formData.append('isMobile', '1')
      formData.append('hal', '1')
      console.log("GET POPULER " + window.localStorage.getItem('alias'));
      //const json = await executePost(BASE_URL + 'getTitikBacaPerpusnas/' + this.state.alias, formData)
      const json = await executePost(TITIK_BACA + window.localStorage.getItem('alias'), formData)
      //const json = JSON.parse(jsonData);
      console.log("getBukuPopuler", json);
      if (json.code == 404) {
        window.location.href = 'https://titikbaca.id'
      } else if (json.code == 200) {


        this.setState({
          lokasi: json.nama_lokasi,
          data: json.data,
          //datakategori: json.list_sub_kategori,
          alias: json.token,
          flagData: true
        })
        if (json.hybrid_type=='smp') {
          this.setState({
            color: 'bg-smp',
            hybrid_umum: false,
            hybrid_smp:true
          })
          window.localStorage.setItem('color', 'bg-smp')
        }
        window.localStorage.setItem('nama', json.nama_lokasi)
        window.localStorage.setItem('alias', json.token)
        
        this.getBerita();
          
        this.getKategori(lat, long, alt)
        this.getTitikBacaTerdekat(lat, long, alt)
        
        this.getRSS(1, 1);
        
        this.nextRss();
        
        this.getRadio();
        
        this.getRSSKominfo();
        
        this.getAudio();
        this.getVideo();
        
        this.getBukuRekomendasi(lat, long ,alt)
        this.getBukuKesehatan(lat, long ,alt)
        this.getYoutube(lat, long, alt);
      } else {
        var parsingMsg = json.msg.substr(0, json.msg.indexOf('<p>'))
        var parsingMsg2 = parsingMsg.replace(/,\s*$/, '')
        // var parsingMsg2 = parsingMsg.substr(0, parsingMsg.indexOf(','));
        this.setState({
          jauh: true,
          isLoading: false,
          msgJauh: parsingMsg2,
          lokasiMsgJauh: json.google_maps,
          lokasi: json.nama_lokasi
        })

        // console.log(json.msg)

        window.localStorage.setItem('nama', json.nama_lokasi)
        window.localStorage.setItem('alias', this.state.alias)
        if (json.nama_lokasi == null) {
          this.setState({ lokasi: 'Selamat Datang' })
          window.localStorage.setItem('nama', 'Selamat Datang')
        }
        /*
        this.getRSS(1, 2)
        this.getTitikBacaTerdekat(lat, long, alt)
        this.getYoutube(lat, long, alt)
        this.getRSSKominfo();
        this.nextRss();
        this.getRadio(); 
        */
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
    
  }

  getBukuRekomendasi = async (lat, long, alt) => {
    try {
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      formData.append('isMobile', '1')
      formData.append('hal', '1')
      formData.append('cat', "ALL")
      const json = await executePost(BASE_URL + "randomBuku/" + this.state.alias, formData)
      this.setState({
        dataRekomendasi: json.data,
        flagRekomendasi: true
      })
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getBukuKesehatan = async (lat, long, alt) => {
    try {
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      formData.append('isMobile', '1')
      formData.append('hal', '1')
      formData.append('cat', "CAT002")
      const json = await executePost(BASE_URL + "randomBuku/" + this.state.alias, formData)
      this.setState({
        dataKesehatan: json.data,
        flagKesehatan: true
      })
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getAudio = async (lat, long, alt) => {
    try {
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      formData.append('isMobile', '1')
      formData.append('hal', '1')
      //const json = await executePost(TITIK_BACA_REKOMENDASI + this.state.alias, formData)
      const json = await executePost(TITIK_BACA_CONTENT_CATEGORIES  +  'CAT008/' + this.state.alias, formData)
      this.setState({
        dataAudio: json.data,
        flagAudio: true
      })
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getVideo = async (lat, long, alt) => {
    try {
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      formData.append('isMobile', '1')
      formData.append('hal', '1')
      //const json = await executePost(TITIK_BACA_REKOMENDASI + this.state.alias, formData)
      const json = await executePost(TITIK_BACA_CONTENT_CATEGORIES  +  'CAT009/' + this.state.alias, formData)
      this.setState({
        dataVideo: json.data,
        flagVideo: true
      })
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getBukuTerlaris = async (lat, long, alt) => {
    try {
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      formData.append('isMobile', '1')
      formData.append('hal', '1')
      const json = await executePost(TITIK_BACA_TERLARIS + this.state.alias, formData)
      this.setState({
        dataTerlaris: json.data
      })
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
    this.getBukuTop()
  }

  getBukuTop = async () => {
    try {
      let ldata = this.state.dataTop;
      let formData = new FormData()
      formData.append('latitude', this.state.lat)
      formData.append('longitude', this.state.long)
      formData.append('altitude', this.state.alt)
      formData.append('isMobile', '1')
      formData.append('hal', this.state.halaman)
      const json = await executePost(TITIK_BACA_TOP + this.state.alias, formData)
      let nextHal = parseInt(this.state.halaman)+1;

      if (json.code==200) {
        this.setState({
          dataTop: ldata.concat(json.data),
          isLoading: false,
          flagDataTop: true,
          halaman: nextHal
        });
        
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isLoading: false, isError: true, errorMessage: 'Network Error!' })
    }
    //this.getBerita()
  }

  getBerita = async () => {
    try {
      let formData = new FormData()
      formData.append('latitude', this.state.lat)
      formData.append('longitude', this.state.long)
      formData.append('altitude', this.state.alt)
      formData.append('isMobile', '1')
      const json = await executePost(TITIK_BACA_BERITA + this.state.alias, formData)
      this.setState({
        dataBerita: json.data,
        flagBerita: true
      })
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  nextRss = async () => {
    let nextHal = parseInt(this.state.halRSS);
    try {
      let oNews = this.state.dataRSS;
      let formData = new FormData()
      formData.append('latitude', '1')
      formData.append('longitude', '1')
      formData.append('altitude', '1')
      formData.append('alias', this.state.alias)
      formData.append('isMobile', '1')
      formData.append('hal', nextHal)
      const json = await executePost(TITIK_BACA_GET_RSS + '/1', formData)

      this.setState({
        dataRSS: oNews.concat(json),
        flagDataRSS: true,
        halRSS: nextHal+1
        //isLoading: false
      });
      
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
    
  }
  getRSS = async (mode, hal) => {
    try {
      let oNews = this.state.news
      let formData = new FormData()
      formData.append('latitude', '1')
      formData.append('longitude', '1')
      formData.append('altitude', '1')
      formData.append('alias', this.state.alias)
      formData.append('isMobile', '1')
      formData.append('hal', hal)
      //const json = await executePost(TITIK_BACA_GET_RSS + '/' + mode, formData)
      //let json = await executeGet("https://kubuku.id/api/titikBaca/downloadRSSKumparan")
      let json = await executeGet("https://kubuku.id/api/titikBaca/downloadRSSRandom/0/0/" + window.localStorage.getItem('alias') + "/1")
      this.setState({
        news: oNews.concat(json),
        flagBerita: true,
        mode: mode,
        hal: 1
        //isLoading: false
      });
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getRSSKominfo = async () => {
    try {
      //const json = await executeGet(TITIK_BACA_GET_RSS + '/jakarta/1')
      
      let json = await executeGet("https://kubuku.id/api/titikBaca/downloadRSSKumparan")
      this.setState({
        dataRssKominfo: json,
        flagRSSKominfo: true,
        hal: 1
        //isLoading: false
      });
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }


  getYoutube = async (lat, long, alt) => {
    try {
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      formData.append('isMobile', '1')
      formData.append('hal', 1)
      const json = await executePost(TITIK_BACA_YOUTUBE + this.state.alias, formData)
      this.setState({
        youtube: json.data,
        flagYoutube: true,
        hal: 1,
        isLoading: false
      })
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getKategori = async (lat, long, alt) => {
    try {
      let alias = this.state.alias
      let alt = 1
      //const json = await executeGet(TITIK_BACA_YOUTUBE + '/' + alias + '/' + lat + '/' + long + '/' + alt + '/1')
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      formData.append('isMobile', '1')
      formData.append('hal', 1)
      const json = await executePost(TITIK_BACA_KATEGORI + this.state.alias, formData)
      this.setState({
        datakategori: json.data,
        isLoading: false
      })
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getRadio = async () => {
    try {
      this.setState({
        isLoading: true
      })
      const json = await executeGet(GET_RADIO)
      if (json.code == 404) {
        this.setState({
          isLoading: false
        })
        //window.location.href = 'https://titikbaca.id'
      } else {
        let newArray = [...json]
        let length = newArray.length

        for (let start = 0; start < length; start++) {
          const randomPosition = Math.floor((newArray.length - start) * Math.random())
          const randomItem = newArray.splice(randomPosition, 1)
          newArray.push(...randomItem)
        }
        this.setState({
          dataRadio: newArray,
          isLoading: false,
          flagRadio: true
        })
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  

  getTitikBacaTerdekat = async (lat, long, alt) => {
    try {
      createNewHeaders()
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      const json = await executePost(BASE_URL + 'titikPerpusnas/', formData)
      // console.log("getTitikBacaTerdekat",formData,  TITIK_BACA, json);
      this.setState({
        dataterdekat: json,
        showMain: true
      })

      let arrayTemp = []
      json.forEach((item) => {
        //console.log("foreach = " + item.alias);
        //console.log("STATAIEAL " + this.state.alias);
        if (item.alias != this.state.alias) {
          //console.log("foreach res= " + JSON.stringify(item));
          arrayTemp.push(item)
          //console.log("arrayTemp = " + JSON.stringify(arrayTemp))
          this.setState({
            isLoading: false,
            showMain: true,
            dataterdekat_array: arrayTemp
          })

          console.log('dataterdekat_array = ' + this.state.dataterdekat_array)
        }
      })
      // console.log("Respone_TITIK = " + JSON.stringify(this.state.dataterdekat_array));
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
    this.getBukuTerlaris(lat,long, alt)
  }
  gotoRSSDetail = async (tipe, id) => {
    window.location.href = '/rss/'  + id;// + '/' + tanggal + '/' + publisher + '/' + btoa(logo) + '/'  + btoa(id) 
  }
  gotoRadioDetail = async (radio) => {
    window.location.href = '/detailRadio/' + radio.id
  }

  gotoDetail = async (id) => {
    let aid = id.split("-");
    id = aid[aid.length-1];
    window.location.href = '/detail/konten/' + id + '/' + this.state.alias + '/REG'
  }

  gotoNewsDetail = async (id) => {
    window.location.href = '/detailBerita/' + id + '/' + this.state.alias
  }

  bacaBerita = async (id, tanggal) => {
    window.location.href = '/bacaBerita/' + this.state.alias + '/' +  btoa(id) + '/' + btoa(tanggal)
  }

  listRSS = async(id) => {
    window.location.href = '/listrss/' + id
  }

  gotoSearch = async (tipe) => {
    window.location.href = '/listBuku/' + tipe + '/' + this.state.alias
  }

  gotoKategori = async (cat) => {
    window.location.href = '/kategori/' + cat + '/' + this.state.alias
  }

  gotoKementerian = async () => {
    window.location.href = '/kementerian/' + this.state.alias
  }

  gotoYoutube = async (id) => {
    window.location.href = '/youtube/' + id
  }

  gotoGames = async () => {
    //window.location.href = "https://perpusnas.titikbaca.id/fruit/index.html";
    window.location.href = '/listgames'
  }

  gotoMusik = async () => {
    //window.location.href = "https://perpusnas.titikbaca.id/fruit/index.html";
    window.location.href = '/listmusik'
  }

  gotoContenCreator = async () => {
    //window.location.href = "https://perpusnas.titikbaca.id/fruit/index.html";
    window.location.href = '/contentcreator'
  }

  render() {
    let ukuran = [{ columns: 2, gutter: 10 },, { mq: '768px', columns: 6, gutter: 10 }];
    return (
      // this.state.isLoading ? <Loader />:
      <div>
        {this.state.isLoading && <SkeletonHome />}
        {/* {console.log("HOME", this.state)}
        {console.log("HOME2", this.state.noGPS)} */}
        {this.state.noGPS && (
          <>
            <ContentNoGPS></ContentNoGPS>
          </>
        )}
        {/* ADA GPS TAPI JAUAHAJAUUUUUUUH */}
        {this.state.jauh && (
          <>
            {/* Section Hero */}
            <ContentJauh></ContentJauh>

          </>
        )}
        {/* GPS AKTIF ADA DI LOKASI NORMALLLLL */}
        {this.state.showMain && this.state.flagData && this.state.flagRekomendasi && (
          <div>
            {/* Search */}
            <div className="search-bar fixed top-[-74px] w-full px-4 py-3 z-50 bg-white shadow-md ease-in duration-300">
              <div className="relative">
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-3 left-[16px] mr-2">
                  <path d="M9.33512 4.80232C9.74423 4.73752 10.0234 4.35334 9.95856 3.94423C9.89376 3.53511 9.50958 3.25599 9.10047 3.32079C6.64008 3.71047 4.71044 5.64012 4.32076 8.1005C4.25596 8.50961 4.53508 8.89379 4.9442 8.95859C5.35331 9.02339 5.73749 8.74426 5.80229 8.33515C6.09032 6.51661 7.51658 5.09035 9.33512 4.80232Z" fill="#d3d3d3" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.61522 0.25 1.25 4.61522 1.25 10C1.25 15.3848 5.61522 19.75 11 19.75C16.3848 19.75 20.75 15.3848 20.75 10C20.75 4.61522 16.3848 0.25 11 0.25ZM2.75 10C2.75 5.44365 6.44365 1.75 11 1.75C15.5563 1.75 19.25 5.44365 19.25 10C19.25 14.5563 15.5563 18.25 11 18.25C6.44365 18.25 2.75 14.5563 2.75 10Z" fill="#d3d3d3" />
                  <path d="M19.5304 17.4698C19.2375 17.1769 18.7626 17.1769 18.4697 17.4698C18.1768 17.7626 18.1768 18.2375 18.4697 18.5304L22.4696 22.5304C22.7625 22.8233 23.2374 22.8233 23.5303 22.5304C23.8232 22.2375 23.8232 21.7626 23.5303 21.4697L19.5304 17.4698Z" fill="#d3d3d3" />
                </svg>
                <input type="text" className="flex items-center w-full pr-4 pl-11 py-2.5 bg-white border border-gray-200 rounded-2xl placeholder:text-gray-400 text-sm focus:outline-none focus:border-orange focus:ring-orange" placeholder="Cari judul buku, penulis, penerbit..." />
              </div>
            </div>
            {/* End Search */}

            {/* Section Hero */}
            <div className={"fixed w-full top-0 left-0 p-4 pb-10 "+ this.state.color +" text-white"}>
              
              <img src={logo_titikbaca} alt="logo titikbaca" className="mb-3"  style={{height:"41px"}} />
              <div className="flex">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                  <path fillRule="evenodd" clipRule="evenodd" d="M14.7808 19.7005C17.0905 16.7551 20 12.4759 20 9.6087C20 4.85424 16.4183 1 12 1C7.58172 1 4 4.85424 4 9.6087C4 12.4759 6.90945 16.7551 9.21921 19.7005C10.4242 21.2371 11.0268 22.0055 12 22.0055C12.9732 22.0055 13.5758 21.2371 14.7808 19.7005ZM12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13Z" fill="#FAFAFA" />
                </svg>
                <h3 className="text-base font-medium line-clamp-1">{this.state.lokasi}</h3>
              </div>
              <div>
              
              <div>
              <div className="grid grid-cols-2 gap-2">
                { this.state.sn=="" ?
                <div className={"mt-4 p-4 " + this.state.darkcolor + " bg-opacity-20 rounded-2xl"}>
                  <p className="text-sm">Cari buku yang ingin kamu baca</p>
                  <button onClick={() => this.gotoSearch(0)} className={"flex w-full mt-2 p-1 items-center text-xs border border-white rounded-full hover:bg-white hover:" + this.state.textcolor + " duration-150 ease-in-out"}>
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2 ml-3">
                      <path
                        d="M19.5304 17.4698C19.2375 17.1769 18.7626 17.1769 18.4697 17.4698C18.1768 17.7626 18.1768 18.2375 18.4697 18.5304L19.5304 17.4698ZM22.4696 22.5304C22.7625 22.8233 23.2374 22.8233 23.5303 22.5304C23.8232 22.2375 23.8232 21.7626 23.5303 21.4697L22.4696 22.5304ZM9.33512 4.80232C9.74423 4.73752 10.0234 4.35334 9.95856 3.94423C9.89376 3.53511 9.50958 3.25599 9.10047 3.32079L9.33512 4.80232ZM4.32076 8.1005C4.25596 8.50961 4.53508 8.89379 4.9442 8.95859C5.35331 9.02339 5.73749 8.74426 5.80229 8.33515L4.32076 8.1005ZM18.4697 18.5304L22.4696 22.5304L23.5303 21.4697L19.5304 17.4698L18.4697 18.5304ZM11 18.25C6.44365 18.25 2.75 14.5563 2.75 10H1.25C1.25 15.3848 5.61522 19.75 11 19.75V18.25ZM19.25 10C19.25 14.5563 15.5563 18.25 11 18.25V19.75C16.3848 19.75 20.75 15.3848 20.75 10H19.25ZM11 1.75C15.5563 1.75 19.25 5.44365 19.25 10H20.75C20.75 4.61522 16.3848 0.25 11 0.25V1.75ZM11 0.25C5.61522 0.25 1.25 4.61522 1.25 10H2.75C2.75 5.44365 6.44365 1.75 11 1.75V0.25ZM9.10047 3.32079C6.64008 3.71047 4.71044 5.64012 4.32076 8.1005L5.80229 8.33515C6.09032 6.51661 7.51658 5.09035 9.33512 4.80232L9.10047 3.32079Z"
                        fill="white"
                      />
                    </svg>
                    Cari Buku
                  </button>
                </div>
                :
                <>
                  
                  <div className={"pl-4 pr-4 grid grid-cols-2"}>
                    <div>
                    Scan Wifi
                    <div className='bg-white p-2 rounded' style={{width: "120px", height:"120px"}}>
                      <img src={qrwifi} style={{maxWidth:"105px"}}></img>
                    </div>
                    
                    </div>
                    
                    <div className={"p-4 w-[300px] " + this.state.darkcolor + " bg-opacity-20 rounded-2xl"}>
                      <p className="text-sm">Cari buku yang ingin kamu baca</p>
                      <button onClick={() => this.gotoSearch(0)} className={"flex w-full mt-2 p-1 items-center text-xs border border-white rounded-full hover:bg-white hover:" + this.state.textcolor + " duration-150 ease-in-out"}>
                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2 ml-3">
                          <path
                            d="M19.5304 17.4698C19.2375 17.1769 18.7626 17.1769 18.4697 17.4698C18.1768 17.7626 18.1768 18.2375 18.4697 18.5304L19.5304 17.4698ZM22.4696 22.5304C22.7625 22.8233 23.2374 22.8233 23.5303 22.5304C23.8232 22.2375 23.8232 21.7626 23.5303 21.4697L22.4696 22.5304ZM9.33512 4.80232C9.74423 4.73752 10.0234 4.35334 9.95856 3.94423C9.89376 3.53511 9.50958 3.25599 9.10047 3.32079L9.33512 4.80232ZM4.32076 8.1005C4.25596 8.50961 4.53508 8.89379 4.9442 8.95859C5.35331 9.02339 5.73749 8.74426 5.80229 8.33515L4.32076 8.1005ZM18.4697 18.5304L22.4696 22.5304L23.5303 21.4697L19.5304 17.4698L18.4697 18.5304ZM11 18.25C6.44365 18.25 2.75 14.5563 2.75 10H1.25C1.25 15.3848 5.61522 19.75 11 19.75V18.25ZM19.25 10C19.25 14.5563 15.5563 18.25 11 18.25V19.75C16.3848 19.75 20.75 15.3848 20.75 10H19.25ZM11 1.75C15.5563 1.75 19.25 5.44365 19.25 10H20.75C20.75 4.61522 16.3848 0.25 11 0.25V1.75ZM11 0.25C5.61522 0.25 1.25 4.61522 1.25 10H2.75C2.75 5.44365 6.44365 1.75 11 1.75V0.25ZM9.10047 3.32079C6.64008 3.71047 4.71044 5.64012 4.32076 8.1005L5.80229 8.33515C6.09032 6.51661 7.51658 5.09035 9.33512 4.80232L9.10047 3.32079Z"
                            fill="white"
                          />
                        </svg>
                        Cari Buku
                      </button>
                    </div>
                  </div>
                </>
                }
                <div className="mt-5 sm:mt-0" style={{marginLeft: "calc(50vw - 140px)"}}>
                    <center>
                    Scan Disini
                    </center>
                    <center>
                    <QRCode
                      className='bg-white p-2 rounded'
                      size={70}
                      style={{ height: "auto", maxWidth: "120px", width: "120px" }}
                      value={this.state.urlTitikBaca}
                      viewBox={`0 0 90 90`}
                    />
                    </center>
                  
                </div>
                </div>
                <div className='text-sm'>SSID: Wifi Titikbaca, password:titikbaca</div>
                </div>
              </div>
              {/*
              <div className="mt-2">
                <span className="text-sm mr-1 float-left">Disponsori oleh:</span>
                <img src={logo_sponsor} alt="logo bca" className="float-left h-4" />
              </div>
              */}
            </div>
            {/* End Section Hero */}

            {/* Main Content Wrap */}
            <div className="relative mt-[250px] mb-[70px] sm:mb-0 sm:mt-[280px] md:mb-[0px] bg-white z-10 before:absolute before:top-[-25px] before:left-0 before:w-full before:h-[25px] before:bg-white before:rounded-t-2xl after:absolute after:w-20 after:h-1 after:top-[-14px] after:left-[38%] after:sm:left-[44%] after:bg-gray-200 after:rounded-full">
              {this.state.flagYoutube && this.state.youtube.length > 0 && (
                <>
                  {/* <!-- Titel Section --> */}
                  <div className="flex justify-between px-4 items-center mt-4 mb-2">
                    <div className="flex">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.98901 4.89052C10.3247 4.62955 13.6756 4.62955 17.0113 4.89052L19.252 5.06582C20.5001 5.16346 21.5211 6.09886 21.7274 7.33363C22.2436 10.4231 22.2436 13.5769 21.7274 16.6664C21.5211 17.9011 20.5001 18.8365 19.252 18.9342L17.0113 19.1095C13.6756 19.3704 10.3247 19.3704 6.989 19.1095L4.7483 18.9342C3.50023 18.8365 2.47921 17.9011 2.2729 16.6664C1.75669 13.5769 1.75669 10.4231 2.2729 7.33363C2.47921 6.09886 3.50023 5.16346 4.7483 5.06582L6.98901 4.89052ZM10.0001 14.4701V9.52986C10.0001 9.29667 10.2545 9.15263 10.4545 9.27261L14.5714 11.7428C14.7656 11.8593 14.7656 12.1407 14.5714 12.2572L10.4545 14.7274C10.2545 14.8474 10.0001 14.7033 10.0001 14.4701Z" fill="#E23D31" />
                      </svg>
                      <h3 className="ml-1 text-base text-black font-semibold">Video Untukmu</h3>
                    </div>
                    <a href="/youtube/0" className={"text-xs font-medium " + this.state.textcolor + " hover:opacity-50 duration-150"}>
                      Lihat Semua
                    </a>
                  </div>
                  {/* <!-- End Title Section --> */}
                  <div className="pl-4 md:hidden sm:block ">
                    <Glider draggable slidesToShow={1.7} slidesToScroll={1}>
                      {this.state.youtube.map((item) => {
                        return (
                          <div className="p-1 pr-2">
                            <div onClick={() => this.gotoYoutube(item.id)} className="mb-1 border bg-white rounded-md shadow-md hover:shadow-lg">
                              <div className="max-h-[155px] overflow-hidden">
                                <img className="rounded-lg" src={item.thumbnail} alt="cover news" />
                              </div>
                              <div className="flex min-h-[50px] p-2.5 items-center">
                                
                                <div className="w-12/12">
                                  <h3 className="mb-1 font-semibold text-sm text-black line-clamp-1">{item.judul}</h3>
                                  <div className="leading-[10px] overflow-hidden">
                                    <div className="inline text-xs text-gray-light mr-0.5">{item.channel}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </Glider>
                  </div>
                  {/** IPAD */}
                  <div className="pl-4 hidden md:block lg:hidden">
                    <Glider draggable slidesToShow={3.5} slidesToScroll={1}>
                      {this.state.youtube.map((item) => {
                        return (
                          <div className="p-1 pr-2">
                            <div onClick={() => this.gotoYoutube(item.id)} className="mb-1 border bg-white rounded-md shadow-md hover:shadow-lg">
                              <div className="max-h-[155px] overflow-hidden">
                                <img className="rounded-lg" src={item.thumbnail} alt="cover news" />
                              </div>
                              <div className="flex min-h-[50px] p-2.5 items-center">
                                
                                <div className="w-12/12">
                                  <h3 className="mb-1 font-semibold text-sm text-black line-clamp-1">{item.judul}</h3>
                                  <div className="leading-[10px] overflow-hidden">
                                    <div className="inline text-xs text-gray-light mr-0.5">{item.channel}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </Glider>
                  </div>
                  <div className="pl-4 hidden lg:block">
                    <Glider draggable slidesToShow={3.5} slidesToScroll={1}>
                      {this.state.youtube.map((item) => {
                        return (
                          <div className="p-1">
                            <div onClick={() => this.gotoYoutube(item.id)} className="mb-2.5 border bg-white rounded-2xl shadow-md hover:shadow-lg">
                              <div className="max-h-[165px] overflow-hidden">
                                <img className="rounded-lg" src={item.thumbnail} alt="cover news" />
                              </div>
                              <div className="flex min-h-[96px] p-2.5 items-center">
                                
                                <div className="w-12/12">
                                  <h3 className="mb-1 font-semibold text-sm text-black line-clamp-2">{item.judul}</h3>
                                  <div className="leading-[10px] overflow-hidden">
                                    <div className="inline text-xs text-gray-light mr-0.5">{item.channel}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </Glider>
                  </div>
                </>
              )}
              
              {/* Section Literasi Keluarga */}
              { this.state.hybrid_umum && 
                <div className="p-4 lg:p-6">
                  <div className="p-4 lg:p-8 grid grid-cols-2 gap-4 lg:gap-10 rounded-2xl shadow-md bg-white border border-slate-50 border-opacity-100">
                    <img src={logoLiterasiKeluarga} alt="literasi keluarga" className="w-full" />
                    <div className="grid grid-cols-1 gap-2 lg:gap-10">
                      <button onClick={() => this.gotoSearch(5)} className="bg-gradient-to-r from-[#9D50BB] to-[#6E48AA] text-white lg:text-lg font-medium rounded-lg lg:rounded-2xl shadow-sm hover:shadow-md"><div className='grid'><div className='mb-1 text-lg sm:text-3xl lg:text-4xl'>Pra Nikah</div><div className='text-[8px] sm:text-base lg:text-lg mt-[-8px]'>&nbsp;</div></div></button>
                      <button onClick={() => this.gotoSearch(6)} className="bg-gradient-to-r from-[#00c6ff] to-[#0072ff] text-white lg:text-lg font-medium rounded-lg lg:rounded-2xl shadow-sm hover:shadow-md"><div className='grid'><div className='mb-1 text-lg sm:text-3xl lg:text-4xl'>Menikah</div><div className='text-[8px] sm:text-base lg:text-lg mt-[-8px]'>literasi keluarga baru dan ibu hamil</div></div></button>
                      <button onClick={() => this.gotoSearch(7)} className="bg-gradient-to-r from-[#FDC830] to-[#F37335] text-white lg:text-lg font-medium rounded-lg lg:rounded-2xl shadow-sm hover:shadow-md"><div className='grid'><div className='mb-1 text-lg sm:text-3xl lg:text-4xl'>Golden Age</div><div className='text-[8px] sm:text-base lg:text-lg mt-[-8px]'>usia emas 0-6 tahun</div></div></button>
                    </div>
                    
                  </div>
                </div>
              }
              
              <div className="px-4 lg:px-6">
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4">
                  <button onClick={() => this.gotoSearch(8)} className="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div className="rounded-l-2xl">
                      <div className="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconIlmuTerapan})`}}></div>
                    </div>
                    <div className="w-7/12 p-2 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">Ilmu Terapan</div>
                  </button>
                  <button onClick={() => this.gotoSearch(9)} className="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div className="rounded-l-2xl">
                      <div className="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconKesehatan})`}}></div>
                    </div>
                    <div className="w-7/12 p-2 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">Kesehatan</div>
                  </button>
                  { this.state.hybrid_umum ? 
                  <>
                  
                  
                  <button onClick={() => this.gotoSearch(10)} className="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div className="rounded-l-2xl">
                      <div className="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconUmum})`}}></div>
                    </div>
                    <div className="w-7/12 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">Koleksi Umum</div>
                  </button>
                  <button onClick={() => this.gotoKementerian()} className="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div className="rounded-l-2xl">
                      <div className="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconKementrian})`}}></div>
                    </div>
                    <div className="w-7/12 p-2 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">Kementerian /Lembaga</div>
                  </button>
                  </>:
                  <>
                  
                  <button onClick={() => this.gotoSearch(31)} className="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div className="rounded-l-2xl">
                      <div className="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconBukuAjar})`}}></div>
                    </div>
                    <div className="w-7/12 p-2 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">Buku Ajar Sekolah</div>
                  </button>
                  <button onClick={() => this.gotoSearch(14)} className="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div className="rounded-l-2xl">
                      <div className="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconKemendikbud})`}}></div>
                    </div>
                    <div className="w-7/12 p-2 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">BSE Kemendikbud</div>
                  </button>
                  </>
                  }
                </div>
              </div>
              {this.state.hybrid_umum && 
              <>
              <div class="flex justify-between px-4 lg:px-6 items-center mt-4 lg:mt-6 mb-2">
                <h3 class="text-base lg:text-xl text-black font-semibold">Video Content Creator</h3>
              </div>
              <div class="px-4 lg:px-6">
                
                <div class="grid grid-cols-1">
                  <button  onClick={() => this.gotoContenCreator()}  class="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div class="rounded-l-2xl" style={{position: "relative"}}>
                        <img src={kreator} style={{width:"100vw", maxHeight:"260px"}}>
                        </img>
                        <div class="w-7/12  lg:p-4 text-black text-lg sm:text-3xl lg:text-4xl text-left font-bold"  style={{position:"absolute", top:"20%", left:"40vw"}}>Content Creator<br/><span class="text-xs sm:text-base lg:text-lg">Video pendek ragam literasi dan perpustakaan</span></div>
                    </div>
                    
                  </button>
                </div>
              </div>
              </>}
              
              {this.state.hybrid_smp && 
              <>
                <div class="flex justify-between px-4 lg:px-6 items-center mt-4 lg:mt-6 mb-2">
                  <h3 class="text-base lg:text-xl text-black font-semibold">Buku Pengayaan SMP</h3>
                </div>
                <div class="px-4 lg:px-6">
                  <div class="grid grid-cols-1">
                    <button onClick={() => this.gotoSearch(30)} class="rounded-2xl overflow-hidden">
                      <div class="rounded-2xl">
                        <img src={iconBukuPengayaan} alt="buku pengayaan SMP" class="w-full h-full" />
                      </div>
                    </button>
                  </div>
                </div>
                
              </>}
              <div class="flex justify-between px-4 lg:px-6 items-center mt-4 lg:mt-6 mb-2">
                <h3 class="text-base lg:text-xl text-black font-semibold">Koleksi Audiobook & Videobook</h3>
              </div>
              <div class="px-4 lg:px-6">
                <div class="grid grid-cols-2 gap-2 lg:gap-4">
                  <button onClick={() => this.gotoSearch(12)} class="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div class="rounded-l-2xl">
                      <div class="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconAudiobook})`}}></div>
                    </div>
                    <div class="w-7/12 p-2 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">Audiobook</div>
                  </button>
                  <button onClick={() => this.gotoSearch(15)} class="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div class="rounded-l-2xl">
                      <div class="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconVideobook})`}}></div>
                    </div>
                    <div class="w-7/12 p-2 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">Videobook</div>
                  </button>
                </div>
              </div>
              
              <div class="px-4 lg:px-6 mt-4">
                <div class="grid grid-cols-1" >
                  <button onClick={() => this.gotoMusik()} class="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    
                    <div class="rounded-l-2xl" style={{position: "relative", maxHeight:"260px"}}>
                      <img src={musik} style={{width:"100vw"}}>
                      </img>
                      <div class="w-11/12 p-2 lg:p-4 text-black text-lg sm:text-3xl lg:text-4xl text-left font-bold" style={{position:"absolute", bottom:"2px", left:"8px"}}>Musik Keluarga<br/><span class="text-xs sm:text-base lg:text-lg">Musik pendukung Literasi Keluarga</span></div>
                    </div>
                  </button>
                </div>
              </div>
              { this.state.hybrid_umum && 
              <>
              <div class="flex justify-between px-4 lg:px-6 items-center mt-4 lg:mt-6 mb-2">
                <h3 class="text-base lg:text-xl text-black font-semibold">Literasi Daerah</h3>
                
              </div>
              <div class="px-4 lg:px-6">
                <div class="grid grid-cols-1">
                  <button onClick={() => this.gotoSearch(21)} class="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div class="rounded-l-2xl">
                      <div class="h-24 w-32 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconLiterasiDaerah})`}}></div>
                    </div>
                    <div class="w-7/12 p-2 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">Literasi Daerah<br/><span class="text-xs">Informasi dan Literatur Daerah</span></div>
                  </button>
                </div>
              </div>
              </>}
              {
                this.state.hybrid_smp && 
                <>
                <div class="flex justify-between px-4 lg:px-6 items-center mt-4 lg:mt-6 mb-2">
                  <h3 class="text-base lg:text-xl text-black font-semibold">P3SMPT Perpusnas RI</h3>
                  
                </div>
                <div class="px-4 lg:px-6">
                  <div class="grid grid-cols-1">
                    <button onClick={() => this.gotoSearch(34)} class="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                      <div class="rounded-l-2xl">
                        <div class="h-24 w-32 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconP3SMPT})`}}></div>
                      </div>
                      <div class="w-7/12 p-2 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">P3SMPT Perpusnas RI<br/><span class="text-xs">Memuat Informasi P3SMPT</span></div>
                    </button>
                  </div>
                </div>
                <div class="flex justify-between px-4 lg:px-6 items-center mt-4 lg:mt-6 mb-2">
                  <h3 class="text-base lg:text-xl text-black font-semibold">Informasi</h3>
                  
                </div>
                <div class="px-4 lg:px-6">
                <div class="grid grid-cols-2 gap-2 lg:gap-4">
                  <button onClick={() => this.gotoSearch(32)} className="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div className="rounded-l-2xl">
                      <div className="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconInfoKemendikbud})`}}></div>
                    </div>
                    <div className="w-7/12 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">Informasi Kemendikbud</div>
                  </button>
                  <button onClick={() => this.gotoSearch(33)} className="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div className="rounded-l-2xl">
                      <div className="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconInfoSekolah})`}}></div>
                    </div>
                    <div className="w-7/12 p-2 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">Informasi Sekolah</div>
                  </button>
                  </div>
                </div>
                
                </>
              }
              { this.state.hybrid_umum && 
              <>
              <div className="flex justify-between px-4 lg:px-6 items-center mt-4 lg:mt-6 mb-2">
                <h3 className="text-base lg:text-xl text-black font-semibold">Koleksi BSE</h3>
              </div>
              <div className="px-4 lg:px-6">
                <div className="grid grid-cols-2 gap-2 lg:gap-4">
                  <button  onClick={() => this.gotoSearch(13)} className="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div className="rounded-l-2xl">
                      <div className="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconKemenag})`}}></div>
                    </div>
                    <div className="w-7/12 p-2 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">BSE Kemenag</div>
                  </button>
                  <button onClick={() => this.gotoSearch(14)} className="flex items-center shadow-md border border-slate-50 border-opacity-100 rounded-2xl overflow-hidden hover:shadow-lg">
                    <div className="rounded-l-2xl">
                      <div className="h-24 w-20 bg-no-repeat bg-left" style={{backgroundImage: `url(${iconKemendikbud})`}}></div>
                    </div>
                    <div className="w-7/12 lg:p-4 text-black text-sm lg:text-lg text-left font-semibold">BSE Kemendikbud</div>
                  </button>
                </div>
              </div>
              </>}
              {this.state.hybrid_umum && 
              <>
              <div class="flex justify-between px-4 lg:px-6 items-center mt-4 lg:mt-6 mb-2">
                <h3 class="text-base lg:text-xl text-black font-semibold">Potensi Lokal</h3>
              </div>
              <div class="px-4 lg:px-6">
                <div class="grid grid-cols-1">
                  <button  onClick={() => this.gotoSearch(20)} class="bg-[#D6F5FF] h-[182px] sm:h-auto lg:h-auto flex items-center shadow-md rounded-2xl overflow-hidden hover:shadow-lg">
                    <div class="rounded-l-2xl pt-4">
                      <img src={iconLokal} alt="" class="w-full" />
                    </div>
                    <div class="w-7/12 p-2 lg:p-4 text-[#6B92AA] text-lg sm:text-3xl lg:text-4xl text-left font-bold">POTENSI LOKAL<br/><span class="text-xs">Buku Penunjang Kegiatan Lokal</span></div>
                  </button>
                </div>
              </div>
              </>}
              {this.state.hybrid_smp && 
              <>
                
                <div class="flex justify-between px-4 lg:px-6 items-center mt-4 lg:mt-6 mb-2">
                  <h3 class="text-base lg:text-xl text-black font-semibold">Permainan Edukasi</h3>
                </div>
                <div class="px-4 lg:px-6">
                  <div class="grid grid-cols-1">
                    <button onClick={() => this.gotoGames()} class="rounded-2xl overflow-hidden">
                      <div class="rounded-2xl">
                        <img src={iconPermainanEdukasi} alt="permainan edukasi" class="w-full h-full" />
                      </div>
                    </button>
                  </div>
                </div>
                
              </>}
                
              {/* Title Section */}
              {this.state.flagKesehatan && (
                <>
                  <div className="flex justify-between px-4 items-center mt-4 mb-2">
                    <h3 className="text-base text-black font-semibold">Buku Kesehatan</h3>
                    <div onClick={() => this.gotoSearch(9)} className={"text-xs font-medium " + this.state.textcolor}>
                      Lihat Semua
                    </div>
                  </div>
                  {/* End Title Section */}
                  { this.state.flagYoutube && 
                  <div className="pl-4 md:hidden sm:block">
                    <Glider slidesToShow={3.3} slidesToScroll={1} draggable>
                      {this.state.dataKesehatan.length > 0 && (
                        <>
                          {this.state.dataKesehatan.slice(0, 6).map((item) => {
                            return (
                              <div className="p-1">
                                <div onClick={() => this.gotoDetail(item.alias)} className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                                  <div className="h-[130px] overflow-hidden">
                                    <img src={item.cover_file} alt="cover buku" className="rounded-xl" />
                                  </div>
                                  <div className="p-2 min-h-[120px]">
                                    <div className="title-book text-sm text-black font-semibold line-clamp-2">{item.judul}</div>
                                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>
                                      Stok: {item.terpakai}/{item.qty}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </Glider>
                  </div>
                  }
                  <div className="pl-4 hidden md:block">
                    <Glider slidesToShow={5.3} slidesToScroll={1} draggable>
                      {this.state.dataKesehatan.length > 0 && (
                        <>
                          {this.state.dataKesehatan.slice(0, 6).map((item) => {
                            return (
                              <div className="p-1">
                                <div onClick={() => this.gotoDetail(item.alias)} className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                                  <div className="h-[130px] overflow-hidden">
                                    <img src={item.cover_file} alt="cover buku" className="rounded-xl" />
                                  </div>
                                  <div className="p-2 min-h-[120px]">
                                    <div className="title-book text-sm text-black font-semibold line-clamp-2">{item.judul}</div>
                                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>
                                      Stok: {item.terpakai}/{item.qty}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </Glider>
                  </div>
                </>
              )}

              {/* Category 
              <div className="grid grid-cols-3 gap-2 mt-4 px-4">
                {this.state.datakategori.length > 0 && (
                  <>
                    {this.state.datakategori.slice(0, 6).map((item, index) => {
                      return (
                        <button onClick={() => this.gotoKategori(item.sub_kategori)} type="button" className={warna[index] + ' px-4 py-3 text-sm text-white font-semibold rounded-lg truncate'}>
                          {item.sub_kategori}
                        </button>
                      )
                    })}
                  </>
                )}
              </div>
              {/* End Category */}

              {/* Title Section */}
              {this.state.dataBerita.length > 0 && (
                <>
                  <div className="flex justify-between px-4 items-center mt-4 mb-2">
                    <h3 className="text-base text-black font-semibold">Berita Sekitar</h3>
                    <a href="/news" className={"text-xs font-medium " + this.state.textcolor }>
                      Lihat Semua
                    </a>
                  </div>
                  {/* End Title Section */}
                  <div className="px-4 md:grid md:grid-cols-2 md:gap-2">
                    {this.state.dataBerita.slice(0, 1).map((item) => {
                      return (
                        <div onClick={() => this.gotoNewsDetail(item.id)} className="mb-2.5 border bg-white rounded-2xl shadow-md hover:shadow-lg">
                          <div className="max-h-[165px] overflow-hidden">
                            <img className="w-full rounded-2xl" src={item.image} alt="cover news" />
                          </div>
                          <div className="px-2.5 py-4">
                            <h3 className="mb-1 text-sm text-black font-semibold line-clamp-2">{item.judul}</h3>
                            <div className="text-xs text-gray-light">{item.sumber}</div>
                            <div className="text-xs text-gray-light">{formatTanggal(item.ins_date)}</div>
                          </div>
                        </div>
                      )
                    })}
                    {this.state.dataBerita.length > 1 && this.state.flagBerita && (
                      <div className="md:mt-3">
                        {this.state.dataBerita.slice(1, 3).map((item) => {
                          return (
                            <div href="#" onClick={() => this.gotoNewsDetail(item.id)} className="flex mb-2.5 p-2.5 border bg-white rounded-2xl shadow-md hover:shadow-lg cursor-pointer" id="local" role="tabpanel" aria-labelledby="local-tab">
                              <div style={{ background: "url('" + item.image + "') center center / cover repeat" }} className={'w-[90px] h-[90px] mr-3 bg-center bg-no-repeat rounded-xl'}></div>
                              <div className="w-8/12">
                                <h3 className="text-sm text-black font-semibold line-clamp-3">{item.judul}</h3>
                                <div className="mt-1 text-xs text-gray-light">{item.sumber}</div>
                                <div className="text-xs text-gray-light">{formatTanggal(item.ins_date)}</div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </>
              )}
              {/* Title Section */}
              {this.state.flagRekomendasi && (
                <>
                  <div className="flex justify-between px-4 items-center mt-4 mb-2">
                    <h3 className="text-base text-black font-semibold">Buku Rekomendasi</h3>
                    <div onClick={() => this.gotoSearch(2)} className={"text-xs font-medium " + this.state.textcolor}>
                      Lihat Semua
                    </div>
                  </div>
                  {/* End Title Section */}
                  { this.state.flagYoutube && 
                  <div className="pl-4 md:hidden sm:block">
                    <Glider slidesToShow={3.3} slidesToScroll={1} draggable>
                      {this.state.dataRekomendasi.length > 0 && (
                        <>
                          {this.state.dataRekomendasi.slice(0, 6).map((item) => {
                            return (
                              <div className="p-1">
                                <div onClick={() => this.gotoDetail(item.alias)} className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                                  <div className="h-[130px] overflow-hidden">
                                    <img src={item.cover_file} alt="cover buku" className="rounded-xl" />
                                  </div>
                                  <div className="p-2 min-h-[120px]">
                                    <div className="title-book text-sm text-black font-semibold line-clamp-2">{item.judul}</div>
                                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>
                                      Stok: {item.terpakai}/{item.qty}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </Glider>
                  </div>
                  }
                  <div className="pl-4 hidden md:block">
                    <Glider slidesToShow={5.3} slidesToScroll={1} draggable>
                      {this.state.dataRekomendasi.length > 0 && (
                        <>
                          {this.state.dataRekomendasi.slice(0, 6).map((item) => {
                            return (
                              <div className="p-1">
                                <div onClick={() => this.gotoDetail(item.alias)} className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                                  <div className="h-[130px] overflow-hidden">
                                    <img src={item.cover_file} alt="cover buku" className="rounded-xl" />
                                  </div>
                                  <div className="p-2 min-h-[120px]">
                                    <div className="title-book text-sm text-black font-semibold line-clamp-2">{item.judul}</div>
                                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>
                                      Stok: {item.terpakai}/{item.qty}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </Glider>
                  </div>
                </>
              )}

              {/* <!-- Titel Section --> */}
              <div className="flex justify-between items-center pl-4 pr-4 mt-4 mb-2">
                  <h3 className="text-base text-black font-semibold">Berita Unggulan</h3>
                </div>
                {/* <!-- End Title Section --> */}
                <div className="m-2 relative overflow-hidden mb-4">
                  {this.state.news.slice(0, 1).map((item) => {
                    return (
                      <div onClick={() => this.gotoRSSDetail(item.tipe, item.id)} className="border bg-white rounded-2xl shadow-md hover:shadow-lg">
                        <img className="cursor-pointer w-full rounded-2xl" src={item.img + '?w=700&q=90'} alt="cover news" />
                        <div className="w-full max-h-32 p-4 absolute bottom-0 left-0 bg-gradient-to-t from-orange rounded-2xl">
                          <h3 className="mb-1 text-sm text-white font-semibold line-clamp-2">{item.title}</h3>
                          <div className="flex">
                            <div className="w-8 h-8 overflow-hidden rounded-full border-[1px] border-slate-300">
                              <img src={item.logo} alt="logo kumparan" />
                            </div>
                            <div className="ml-2 text-white text-xs">
                              <div>{item.publisher}</div>
                              <div>{formatTanggal(item.date)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    )
                  })}
                </div>
                <div className='pl-4 pr-4  md:grid md:grid-cols-2 md:gap-2'>
                    {this.state.news.length > 0 && this.state.flagBerita && (
                      <>
                        {this.state.news.slice(1,5).map((item) => {
                          return (
                            <>
                              <div onClick={() => this.gotoRSSDetail(item.tipe, item.id)} className="flex mb-2.5 p-2.5 bg-white border rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                                
                                <div className="w-9/12 mr-4">
                                  <h3 className="mt-1 font-semibold text-sm text-black line-clamp-2">{item.title}</h3>
                                  <div className="mt-1 flex items-center">
                                    <div className="w-6 h-6 overflow-hidden rounded-full border-[1px] border-slate-300">
                                      <img src={item.logo} alt="logo kumparan" />
                                    </div>
                                    <div className="ml-2 text-white text-xs">
                                      <div className="text-xs text-gray-light">{item.publisher}</div>
                                      <div className="text-xs text-gray-light">{formatTanggal(item.date)}</div>
                                    </div>
                                    
                                  </div>
                                </div>
                                <div style={{ background: "url('" + item.img + "') center center / cover repeat" }} className={'w-[90px] h-[90px] bg-center bg-no-repeat rounded-xl'}></div>
                              </div>
                            </>
                          )
                        })}
                      </>
                    )}
                  
                </div>

              
              
              {/* Title Section */}
              <div className="flex justify-between px-4 items-center mt-4 mb-2">
                <h3 className="text-base text-black font-semibold">Audiobook</h3>
                <div onClick={() => this.gotoSearch(12)} className={"text-xs font-medium " + this.state.textcolor}>
                  Lihat Semua
                </div>
              </div>
              {/* <!-- End Titel Section --> */}
              { this.state.flagYoutube && 
              <div className="pl-4 sm:block md:hidden">
              <Glider draggable slidesToShow={3.2} slidesToScroll={1}>
                  {this.state.dataAudio.length > 0 && (
                    <>
                      {this.state.dataAudio.map((item) => {
                        return (
                          <div className="p-1">
                            <div onClick={() => this.gotoDetail(item.alias)} className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                              <div className="h-[130px] overflow-hidden">
                                <img src={item.cover_file} alt="cover buku" className="rounded-xl" />
                              </div>
                              <div className="p-2 min-h-[120px]">
                                <div className="title-book text-sm text-black font-semibold line-clamp-2">{item.judul}</div>
                                <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                                <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                                <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>
                                  Stok: {item.terpakai}/{item.qty}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </Glider>
                
              </div>
              }
              <div className="pl-4 hidden md:block">
              <div className="grid grid-cols-5 gap-2">
                  {this.state.dataAudio.length > 0 && (
                    <>
                      {this.state.dataAudio.slice(0, 5).map((item) => {
                        return (
                          <div className="p-1">
                            <div onClick={() => this.gotoDetail(item.alias)} className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                              <div className="h-[130px] overflow-hidden">
                                <img src={item.cover_file} alt="cover buku" className="rounded-xl" />
                              </div>
                              <div className="p-2 min-h-[120px]">
                                <div className="title-book text-sm text-black font-semibold line-clamp-2">{item.judul}</div>
                                <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                                <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                                <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>
                                  Stok: {item.terpakai}/{item.qty}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </div>
                
              </div>

              {/* Title Section */}

              <div className="flex justify-between px-4 items-center mt-4 mb-2">
                <h3 className="text-base text-black font-semibold">Titikbaca Terdekat</h3>
              </div>
              {/* End Title Section */}
              <div className="px-4 md:hidden sm:block ">
                <div className="grid grid-cols-3 gap-4">
                  {this.state.dataterdekat_array.length > 0 && (
                    <>
                      {this.state.dataterdekat_array.slice(0, 3).map((item) => {
                        // { console.log('titik_terdekat = ' + JSON.stringify(item)) }
                        // { console.log('titik_terdekat = ' + this.state.alias) }
                        let mySentence = item.nama.toLowerCase()
                        let words = mySentence.split(' ')

                        for (let i = 0; i < words.length; i++) {
                          console.log("=========== " + words[i]);
                          if (words[i].length>1) {
                            words[i] = words[i][0].toUpperCase() + words[i].substr(1)
                          }
                        }

                        let titik = words.join(' ')
                        return (
                          <a href={'/' + item.alias}>
                            <div className="text-center">
                              <div className="rounded-full p-4">
                                <img src={image_location} alt="location" />
                              </div>
                              <div className="mt-2 text-sm text-black font-semibold line-clamp-2">{titik}</div>
                            </div>
                          </a>
                        )
                      })}
                    </>
                  )}
                </div>
              </div>
              <div className="px-4 hidden md:block ">
                <div className="grid grid-cols-6 gap-4">
                  {this.state.dataterdekat_array.length > 0 && (
                    <>
                      {this.state.dataterdekat_array.slice(0, 6).map((item) => {
                        // { console.log('titik_terdekat = ' + JSON.stringify(item)) }
                        // { console.log('titik_terdekat = ' + this.state.alias) }
                        let mySentence = item.nama.toLowerCase()
                        let words = mySentence.split(' ')

                        for (let i = 0; i < words.length; i++) {
                          if(words[i].length>1) {
                          words[i] = words[i][0].toUpperCase() + words[i].substr(1)
                          }
                        }

                        let titik = words.join(' ')
                        return (
                          <a href={'/' + item.alias}>
                            <div className="text-center">
                              <div className="rounded-full p-4">
                                <img src={image_location} alt="location" />
                              </div>
                              <div className="mt-2 text-sm text-black font-semibold line-clamp-2">{titik}</div>
                            </div>
                          </a>
                        )
                      })}
                    </>
                  )}
                </div>
              </div>
              {/* Title Section */}
              <div className="flex justify-between px-4 items-center mt-4 mb-2">
                <h3 className="text-base text-black font-semibold">Paling banyak dilihat</h3>
                <div onClick={() => this.gotoSearch(3)} className={"text-xs font-medium " + this.state.textcolor}>
                  Lihat Semua
                </div>
              </div>
              {/* End Title Section */}
              <div className="px-4 md:grid md:grid-cols-2 md:gap-2">
                {this.state.dataTerlaris.length > 0 && (
                  <>
                    {this.state.dataTerlaris.slice(0, 6).map((item) => {
                      return (
                        <div className="mb-2.5 border border-gray-border bg-white p-2.5 rounded-2xl shadow-md">
                          <div onClick={() => this.gotoDetail(item.alias)} className="flex">
                            <div className="w-4/12 md:w-3/12">
                              <img className="rounded-xl" src={item.cover_file} alt="cover book" />
                            </div>
                            <div className="w-8/12 md:w-9/12 ml-3">
                              <h3 className="font-semibold text-sm text-black line-clamp-2">{item.judul}</h3>
                              <div className="mt-1 text-xs text-gray-light">{item.penulis}</div>
                              <div className="mt-3 text-xs text-gray-light line-clamp-3 sm:line-clamp-6">
                                <div dangerouslySetInnerHTML={{ __html: item.sinopsis }} />
                              </div>
                              <div className={"stock mt-2 text-sm " + this.state.textcolor + " font-semibold"}>
                                Stok: {item.terpakai}/{item.qty}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
              </div>

              {/* Title Section */}
              <div className="flex justify-between px-4 items-center mt-4 mb-2">
                <h3 className="text-base text-black font-semibold">Videobook</h3>
                <div onClick={() => this.gotoSearch(12)} className={"text-xs font-medium " + this.state.textcolor}>
                  Lihat Semua
                </div>
              </div>
              {/* <!-- End Titel Section --> */}
              { this.state.flagYoutube && 
              <div className="pl-4 sm:block md:hidden">
                <Glider draggable slidesToShow={3.2} slidesToScroll={1}>
                  {this.state.dataVideo.length > 0 && (
                    <>
                      {this.state.dataVideo.map((item) => {
                        return (
                          <div className="p-1">
                            <div onClick={() => this.gotoDetail(item.alias)} className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                              <div className="h-[130px] overflow-hidden">
                                <img src={item.cover_file} alt="cover buku" className="rounded-xl" />
                              </div>
                              <div className="p-2 min-h-[120px]">
                                <div className="title-book text-sm text-black font-semibold line-clamp-2">{item.judul}</div>
                                <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                                <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                                <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>
                                  Stok: {item.terpakai}/{item.qty}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </Glider>
                
              </div>
              }
              <div className="pl-4 hidden md:block">
              <div className="grid grid-cols-5 gap-2">
                  {this.state.dataVideo.length > 0 && (
                    <>
                      {this.state.dataVideo.slice(0, 5).map((item) => {
                        return (
                          <div className="p-1">
                            <div onClick={() => this.gotoDetail(item.alias)} className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                              <div className="h-[130px] overflow-hidden">
                                <img src={item.cover_file} alt="cover buku" className="rounded-xl" />
                              </div>
                              <div className="p-2 min-h-[120px]">
                                <div className="title-book text-sm text-black font-semibold line-clamp-2">{item.judul}</div>
                                <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                                <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                                <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>
                                  Stok: {item.terpakai}/{item.qty}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </div>
              </div>
              {/* BERITA HERE! */}
              {this.state.flagRSSKominfo && this.state.dataRssKominfo.length >0 &&
                <>
                  <div >
                    <div className="md:mt-3 p-2">
                          <div className="hidden md:block">
                          <div className="flex justify-between items-center m-2 mt-4 mb-2">
                            <div className="flex">
                              <h3 className="ml-1 text-base text-black font-semibold">Berita</h3>
                            </div>
                            <a href="/listrss/0" className={"text-xs font-medium " + this.state.textcolor + " hover:opacity-50 duration-150"}>
                              Lihat Semua
                            </a>
                          </div>
                            <div className='grid grid-cols-2' id="local" role="tabpanel" aria-labelledby="local-tab">
                              {this.state.dataRssKominfo.map((item) => {
                                return (
                                  <>
                                    <div onClick={() => this.gotoRSSDetail(item.link, item.id)} className="flex mb-2.5 p-2.5 m-1 bg-white border rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                                    <div style={{ background: "url('" + item.img + "') center center / cover repeat" }} className={'w-[90px] h-[90px] ml-3 mr-3 bg-center bg-no-repeat rounded-xl'}></div>
                                    <div className="w-9/12">
                                      <h3 className="mt-1 font-semibold text-sm text-black line-clamp-2">{item.title}</h3>
                                      <div className="mt-1 flex items-center">
                                        <div className="w-6 h-6 overflow-hidden rounded-full border-[1px] border-slate-300">
                                          <img src={item.logo} alt="logo kumparan" />
                                        </div>
                                        <div className="ml-2 text-white text-xs">
                                          <div className="text-xs text-gray-light">{item.publisher}</div>
                                          <div className="text-xs text-gray-light">{formatTanggal(item.date)}</div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    </div>
                                  </>
                                )
                              }
                              )}
                            </div>
                            
                          </div>
                          
                          <div className="px-2 md:hidden">
                          <div className="flex justify-between items-center mt-4 mb-2">
                            <div className="flex">
                              <h3 className="ml-1 text-base text-black font-semibold">Berita</h3>
                            </div>
                            <a href="/listrss/0" className={"text-xs font-medium " + this.state.textcolor + " hover:opacity-50 duration-150"}>
                              Lihat Semua
                            </a>
                          </div>
                            {this.state.dataRssKominfo.slice(0,4).map((item, index) => {
                              
                              return (
                                <>
                                  <div onClick={() => this.gotoRSSDetail(item.link, item.id)} className="flex mb-2.5 p-2 bg-white border rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                                    <div style={{ background: "url('" + item.img + "') center center / cover repeat" }} className={'w-[90px] h-[90px] mr-3 bg-center bg-no-repeat rounded-xl'}></div>
                                    <div className="w-9/12">
                                      <h3 className="mt-1 font-semibold text-sm text-black line-clamp-2">{item.title}</h3>
                                      <div className="mt-1 flex items-center">
                                        <div className="w-6 h-6 overflow-hidden rounded-full border-[1px] border-slate-300">
                                          <img src={item.logo} alt="logo kumparan" />
                                        </div>
                                        <div className="ml-2 text-white text-xs">
                                          <div className="text-xs text-gray-light">{item.publisher}</div>
                                          <div className="text-xs text-gray-light">{formatTanggal(item.date)}</div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    </div>
                                </>
                              )
                            
                            }
                            )}
                          </div>
                    </div>
                  </div>
                </>
              }
                {this.state.flagYoutube && 
                  <div style={{ position : "fixed", right :  "10px" , bottom : "100px"}}>
                    <Draggable>
                      <div style={{width: "96px", zIndex: "500"}}>
                        <img  src={mascot} alt="mascot"></img>
                      </div>
                    </Draggable>
                  </div>
                }
              <div className="px-4 hidden md:block ">
                <div className="grid grid-cols-6 gap-4">
                  {this.state.dataterdekat.length > 0 && (
                    <>
                      {this.state.dataterdekat.slice(0, 7).map((item) => {
                        let mySentence = item.nama.toLowerCase()
                        let words = mySentence.split(' ')

                        for (let i = 0; i < words.length; i++) {
                          if (words[i].length>1) {
                          words[i] = words[i][0].toUpperCase() + words[i].substr(1)
                          }
                        }

                        let titik = words.join(' ')
                        if (item.alias != this.state.alias) {
                          return (
                            <a href={'/' + item.alias}>
                              <div className="text-center">
                                <div className="rounded-full p-4">
                                  <img src={image_location} alt="location" />
                                </div>
                                <div className="mt-2 text-sm text-black font-semibold line-clamp-2">{titik}</div>
                              </div>
                            </a>
                          )
                        }
                      })}
                    </>
                  )}
                </div>
              </div>
              
              <div className="px-4">
                {/* Title Section */}
                <div className="flex justify-between items-center mt-4 mb-2">
                  <h3 className="text-base text-black font-semibold">Paling Banyak Dibaca</h3>
                  <div onClick={() => this.gotoSearch(0)} className={"text-xs font-medium " + this.state.textcolor + " hover:opacity-50 duration-150"}>
                    Lihat Semua
                  </div>
                </div>
                {/* End Title Section */}
                {this.state.flagDataTop && this.state.dataTop.length > 0 && (
                <Masonry
                  breakpointCols={{ default: 6, 680: 2}}
                  className="flex w-auto"
                  >

                  {this.state.dataTop.map((item, i) => {
                    return (
                      <div key={i} onClick={() => this.gotoDetail(item.alias)} className="m-2 mb-4 break-inside-avoid rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                        <div className="h-[130px] overflow-hidden">
                          <img src={item.cover_file} alt="cover buku" className="w-full rounded-xl" />
                        </div>
                        <div className="p-2">
                          <div className="title-book text-sm text-black font-semibold line-clamp-3">{item.judul}</div>
                          <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                          <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                          <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>
                            Stok: {item.terpakai}/{item.qty}
                          </div>
                        </div>
                      </div>
                    )}
                  )}

                </Masonry>
                )}
                
                {!this.state.flagMoreSearch && (
                <div className="text-center mb-16 h-[88px]">
                  <button type="button" onClick={() => this.getBukuTop()} className={"mt-2 py-2 px-8 border-[1px] border-orange " + this.state.textcolor + " text-sm font-semibold text-center rounded-full"}>
                    Lihat Lebih Banyak
                  </button>
                </div>
              )}
              </div>
            </div>
            {/* End Main Content Wrap */}

            {/* Ads Video */}
            {/* <div className="fixed bottom-20 right-2 z-50 ads">
              <div className="relative">
                <img src={icon_arrow} alt="arrow" className="arrow absolute bottom-5 left-2" />
                <img src={icon_ads} alt="ads video" className="w-20" />
              </div>
            </div> */}
            {/* End Ads Video */}

            {/* Search Page */}
            <div className="hidden fixed top-0 left-0 w-screen h-screen bg-white z-50">
              <div className="shadow-md mb-3 p-3">
                <div className="flex items-center">
                  <div className="flex-0">
                    <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                      <path d="M11.0303 8.53033C11.3232 8.23744 11.3232 7.76256 11.0303 7.46967C10.7374 7.17678 10.2626 7.17678 9.96967 7.46967L5.96967 11.4697C5.82322 11.6161 5.75 11.8081 5.75 12C5.75 12.1017 5.77024 12.1987 5.80691 12.2871C5.84351 12.3755 5.89776 12.4584 5.96967 12.5303L9.96967 16.5303C10.2626 16.8232 10.7374 16.8232 11.0303 16.5303C11.3232 16.2374 11.3232 15.7626 11.0303 15.4697L8.31066 12.75H18C18.4142 12.75 18.75 12.4142 18.75 12C18.75 11.5858 18.4142 11.25 18 11.25H8.31066L11.0303 8.53033Z" fill="#383838" />
                    </svg>
                  </div>
                  <div className="flex-1">
                    <div className="relative">
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-3 left-[16px] mr-2">
                        <path d="M9.33512 4.80232C9.74423 4.73752 10.0234 4.35334 9.95856 3.94423C9.89376 3.53511 9.50958 3.25599 9.10047 3.32079C6.64008 3.71047 4.71044 5.64012 4.32076 8.1005C4.25596 8.50961 4.53508 8.89379 4.9442 8.95859C5.35331 9.02339 5.73749 8.74426 5.80229 8.33515C6.09032 6.51661 7.51658 5.09035 9.33512 4.80232Z" fill="#d3d3d3" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.61522 0.25 1.25 4.61522 1.25 10C1.25 15.3848 5.61522 19.75 11 19.75C16.3848 19.75 20.75 15.3848 20.75 10C20.75 4.61522 16.3848 0.25 11 0.25ZM2.75 10C2.75 5.44365 6.44365 1.75 11 1.75C15.5563 1.75 19.25 5.44365 19.25 10C19.25 14.5563 15.5563 18.25 11 18.25C6.44365 18.25 2.75 14.5563 2.75 10Z" fill="#d3d3d3" />
                        <path d="M19.5304 17.4698C19.2375 17.1769 18.7626 17.1769 18.4697 17.4698C18.1768 17.7626 18.1768 18.2375 18.4697 18.5304L22.4696 22.5304C22.7625 22.8233 23.2374 22.8233 23.5303 22.5304C23.8232 22.2375 23.8232 21.7626 23.5303 21.4697L19.5304 17.4698Z" fill="#d3d3d3" />
                      </svg>
                      <input type="text" className="flex items-center w-full pr-4 pl-11 py-2.5 bg-white border border-gray-200 rounded-2xl placeholder:text-gray-400 text-sm focus:outline-none focus:border-orange focus:ring-orange" placeholder="Cari judul buku, penulis, penerbit..." />
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 pb-28 grid grid-cols-2 gap-2 h-screen overflow-y-auto">
                <div className="rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                  <div className="h-[180px] overflow-hidden">
                    <img src="https://kubuku.id/prod/img/cover/C9789797569624.JPG" alt="cover buku" className="w-full rounded-xl" />
                  </div>
                  <div className="p-2">
                    <div className="title-book text-sm text-black font-semibold line-clamp-3">Konseling Lintas</div>
                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">Dr. A.A. Ngurah Adhiputra, M.Pd</div>
                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">Graha Ilmu</div>
                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>Stok: 1/3</div>
                  </div>
                </div>
                <div className="rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                  <div className="h-[180px] overflow-hidden">
                    <img src="https://kubuku.id/prod/img/cover/C9789797565626.JPG" alt="cover buku" className="w-full rounded-xl" />
                  </div>
                  <div className="p-2">
                    <div className="title-book text-sm text-black font-semibold line-clamp-3">Perpajakan: Teori & Teknis Perhitungan</div>
                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">Siti Kurnia Rahayu;Ely Suhayati</div>
                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">Graha Ilmu</div>
                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>Stok: 1/3</div>
                  </div>
                </div>
                <div className="rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                  <div className="h-[180px] overflow-hidden">
                    <img src="https://kubuku.id/prod/img/cover/978-623-228-906-2.jpg" alt="cover buku" className="w-full rounded-xl" />
                  </div>
                  <div className="p-2">
                    <div className="title-book text-sm text-black font-semibold line-clamp-3">Agroindustri Ubikayu Edisi 2</div>
                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">Kasma Iswari; Atman</div>
                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">Graha Ilmu</div>
                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>Stok: 1/3</div>
                  </div>
                </div>
                <div className="rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                  <div className="h-[180px] overflow-hidden">
                    <img src="https://kubuku.id/prod/img/cover/C9789797567293.JPG" alt="cover buku" className="w-full rounded-xl" />
                  </div>
                  <div className="p-2">
                    <div className="title-book text-sm text-black font-semibold line-clamp-3">Teori dan Aplikasi Sistem Digital</div>
                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">Eko Budi Purwanto</div>
                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">Graha Ilmu</div>
                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>Stok: 1/3</div>
                  </div>
                </div>
                <div className="rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                  <div className="h-[180px] overflow-hidden">
                    <img src="https://kubuku.id/prod/img/cover/C9789797569624.JPG" alt="cover buku" className="w-full rounded-xl" />
                  </div>
                  <div className="p-2">
                    <div className="title-book text-sm text-black font-semibold line-clamp-3">Konseling Lintas</div>
                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">Dr. A.A. Ngurah Adhiputra, M.Pd</div>
                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">Graha Ilmu</div>
                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>Stok: 1/3</div>
                  </div>
                </div>
                <div className="rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                  <div className="h-[180px] overflow-hidden">
                    <img src="https://kubuku.id/prod/img/cover/C9789797565626.JPG" alt="cover buku" className="w-full rounded-xl" />
                  </div>
                  <div className="p-2">
                    <div className="title-book text-sm text-black font-semibold line-clamp-3">Perpajakan: Teori & Teknis Perhitungan</div>
                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">Siti Kurnia Rahayu;Ely Suhayati</div>
                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">Graha Ilmu</div>
                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>Stok: 1/3</div>
                  </div>
                </div>
                <div className="rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                  <div className="h-[180px] overflow-hidden">
                    <img src="https://kubuku.id/prod/img/cover/978-623-228-906-2.jpg" alt="cover buku" className="w-full rounded-xl" />
                  </div>
                  <div className="p-2">
                    <div className="title-book text-sm text-black font-semibold line-clamp-3">Agroindustri Ubikayu Edisi 2</div>
                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">Kasma Iswari; Atman</div>
                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">Graha Ilmu</div>
                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>Stok: 1/3</div>
                  </div>
                </div>
                <div className="rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                  <div className="h-[180px] overflow-hidden">
                    <img src="https://kubuku.id/prod/img/cover/C9789797567293.JPG" alt="cover buku" className="w-full rounded-xl" />
                  </div>
                  <div className="p-2">
                    <div className="title-book text-sm text-black font-semibold line-clamp-3">Teori dan Aplikasi Sistem Digital</div>
                    <div className="author mt-1 text-xs text-gray-light line-clamp-1">Eko Budi Purwanto</div>
                    <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">Graha Ilmu</div>
                    <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>Stok: 1/3</div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Search Page */}
          </div>
        )}
        {/* {console.log("READY TO SHOW", this.state.showMain, this.state.showMain, this.state.flagData, this.state.flagRekomendasi)} */}
        
      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate()
  return <Home {...props} navigate={navigate} params={useParams()} />
}

export default WithNavigate
