//--- TOKO KUBUKU ---//
const BASE_URL = 'https://kubuku.id/api/titikBaca/';
//const BASE_URL = '../../api/titikBaca/';
//const BASE_URL = 'https://api.bookubuku.id/bukubuku/';
const KONFIGURASI = BASE_URL + 'konfigurasi';
const GET_RADIO = BASE_URL + 'getRadio';
const TITIK_BACA = BASE_URL + 'titikBaca/';
const TITIK_BACA_REKOMENDASI = BASE_URL + 'titikBacaRekomendasi/';
const TITIK_BACA_TERLARIS = BASE_URL + 'titikBacaTerlaris/';
const TITIK_BACA_TOP = BASE_URL + 'titikBacaTop/';
const TITIK_BACA_KATEGORI = BASE_URL + 'titikBacaKategori/';
const TITIK_BACA_BERITA = BASE_URL + 'titikBacaBerita/';
const TITIK_BACA_GET_LOKASI = BASE_URL + 'titikBacaLokasi/';
const TITIK_BACA_GET_THEMES = BASE_URL + 'titikBacaThemes/';
const TITIK_BACA_GET_RSS = BASE_URL + 'titikBacaRSS';
const TITIK_BACA_YOUTUBE = BASE_URL + 'titikBacaYoutube/';
const TITIK_BACA_CEKLOKASI = BASE_URL + 'cekLokasi/';
const TITIK_BACA_RETURN_BOOK = BASE_URL + 'returnBook/';
const TITIK_BACA_CONTENT_CATEGORIES = BASE_URL + 'contentCategories/';

export {
    BASE_URL,
    KONFIGURASI, 
    GET_RADIO,
    TITIK_BACA,
    TITIK_BACA_REKOMENDASI,
    TITIK_BACA_TERLARIS,
    TITIK_BACA_TOP,
    TITIK_BACA_BERITA,
    TITIK_BACA_GET_LOKASI,
    TITIK_BACA_GET_RSS,
    TITIK_BACA_KATEGORI,
    TITIK_BACA_YOUTUBE,
    TITIK_BACA_CEKLOKASI,
    TITIK_BACA_GET_THEMES,
    TITIK_BACA_RETURN_BOOK,
    TITIK_BACA_CONTENT_CATEGORIES
}