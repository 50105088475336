import React, { Component } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { v4 as uuidv4 } from 'uuid'
import { GET_RADIO, TITIK_BACA_GET_THEMES } from '../api'
import { executeGet, goBack } from '../functions/Kubuku'
import SkeletonRadioDetail from '../components/SkeletonRadioDetail'
import classNames from 'classnames'

import { useParams, useNavigate } from 'react-router-dom'

function WithNavigate(props) {
    let navigate = useNavigate()
    return <Splash {...props} navigate={navigate} params={useParams()} />
}

class Splash extends Component {
    constructor(props) {
        super(props)
        this.state = {
            muted: true,
            url: '',
            logo: '',
            nama: '',
            tagline: '',
            putar: false,
            pulse: 'animate-none',
            isLoading: true,
            flagData: false,
            flagDataList: false,
            radioStatus: '',
            data: [],
            jsonRadio: [],
            idRadio: 0,
            alias: 'kfh-12f9-kas',
            lokasi: '',
            imgHead: '',
            imgSplash: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOdAAADnQBaySz1gAAABh0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzT7MfTgAAABZ0RVh0Q3JlYXRpb24gVGltZQAxMS8zMC8yMu+YlTQAAAANSURBVAiZY/j//z8DAAj8Av6Fzas0AAAAAElFTkSuQmCC'
        }
        console.log(this.props)
    }

    componentDidMount = async () => {
        // this.getRadio(1)
        //this.getListRadio();
        // this.getRandom()
        window.localStorage.setItem('splash',1);
        if (window.localStorage.getItem('uuid') == null) {
            let uuid = uuidv4()
            window.localStorage.setItem('uuid', uuid)
        }
        
        this.getTheme();
    }

    getTheme = async () => {
        let alias = this.state.alias;
        let al = String(this.props.params.alias)
        if (al === 'home' || al === 'undefined') {
            alias = window.localStorage.getItem('alias')
        } else {
            alias = this.props.params.alias;
        }
        
        try {
          console.log("ALIAS " + alias + " GET THEME")
          const json = await executeGet(TITIK_BACA_GET_THEMES + alias);
          
          window.localStorage.setItem('themes', JSON.stringify( json));
          console.log('theme = ' + JSON.stringify(json))
          if (json.code == 200) {
            if (json.warna!="") {
              this.setState({
                color: json.warna
              });
            }
            if (json.dark!="") {
              this.setState({
                darkcolor: json.dark
              });
            }
            if (json.text!="") {
              this.setState({
                textcolor: json.text
              });
            }
            if (json.head!="") {
              this.setState({
                imgHead: json.head
              });
            }
            if (json.splash!="") {
                console.log('splash = ' + json.splash)
              this.setState({
                flagData: true,
                imgSplash: json.splash
              });
            }
            this.setState({
                flagData: true
            });
            if (json.splash!="") {
                setTimeout(() => {
                    window.localStorage.setItem('splash',0);
                    window.location.href = '/home/' + alias
                }, 10);
            } else {
                window.localStorage.setItem('splash',0);
                window.location.href = '/home/' + alias 
            }
          }
        } catch (error) {
          this.setState({ isError: true, errorMessage: 'Network Error!' })
        }
      }

    

    render() {
        let pulse = classNames('flex items-center justify-center space-x-2 ', this.state.pulse)
        return (
            <div>
                {this.state.flagData && (
                    <>
                        <div className="mt-[330px]  text-center">
                            <div onClick={() => this.muted()} className="cursor-pointer h-[250px] w-[250px]  text-center mx-auto">
                                <img src={this.state.imgSplash} alt="logo" className="mx-auto" />
                                    
                            </div>
                            {/* <h3 className="text-xl text-black font-semibold">{this.state.nama}</h3>
                            <div className="text-gray-light text-sm mb-4">{this.state.tagline}</div> */}
                        </div>
                    </>
                )}
            </div>
        )
    }
}

export default WithNavigate