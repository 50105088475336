import React, { Component } from 'react'
import {TITIK_BACA_CONTENT_CATEGORIES, TITIK_BACA, TITIK_BACA_TOP, TITIK_BACA_REKOMENDASI, TITIK_BACA_TERLARIS } from '../api'
import { executeGet, executePost, updateHeaders } from '../functions/Kubuku'
import LazyLoad from 'react-lazy-load';

import SkeletonSearch from '../components/SkeletonSearch'
import { useParams, useNavigate } from 'react-router-dom'
import Masonry from 'react-masonry-css'
import logoLiterasiKeluarga from "../assets/images/logo_literasikeluarga.png";

function WithNavigate(props) {
  let navigate = useNavigate()
  return <LiterasiKeluarga {...props} navigate={navigate} params={useParams()} />
}

class LiterasiKeluarga extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      showMain: false,
      params: this.props.params,
      dataBerita: '',
      alias: 'TitikbacaGedungDPRRI',
      lokasi: '',
      jauh: false,
      msgJauh: '',
      lokasiMsgJauh: '',
      lat: '-7.557130246009252',
      long: '110.79448368501073',
      alt: '1',
      flagData: false,
      dataTop: [],
      hal: 1,
      mode: 1,
      search: '',
      flagNoData: false,
      msgTitleNoData: 'Buku Tidak Ditemukan',
      msgNoData: 'Buku yang anda cari tidak ditemukan, silahkan cari buku lain',
      flagMoreSearch: false,
      color: 'bg-orange',
      darkcolor: 'bg-orang-dark',
      textcolor: 'text-orange',
      imgHead: '',
      imgSplash: '',
      firstRun: true,
      tipe: "0",
      tokensms: '',
      tokenvalid: true
    }
  }

  componentDidMount = async () => {
    if (this.state.firstRun) {
      this.setState({
        alias: window.localStorage.getItem('alias'),
        firstRun: false,
        tokensms: this.props.params.token
      })
      this.getTheme();
      this.getLocation()
    }
  }

  locationInit = async (long, lat, alt) => {
    let urltoken = "https://kubuku.id/api/titikBaca/cekToken/" + this.props.params.token
    console.log(urltoken)
    var response = await executeGet(urltoken);
    console.log(response)
    console.log(response.code);
    if (response.code == "200") {
        
        const url = "https://kubuku.id/api/titikBaca/getAlias/undefined/" + lat + '/' + long;
        const response = await executeGet(url);
        console.log(response);
        if (response.code == 200) {
            //console.log(JSON.stringify(response));
            if (response.total >= 1) {
                let data = response.data[0];
                this.setState({alias: data.alias})
                localStorage.setItem("alias", data.alias)
                this.loadData(lat, long, alt, '0')
            } else {
                //SHOW MODAL TO CHOOSE LOCATION 
            }
        } else {
            
        }
    } else {
        this.setState({
            isLoading: false,
            flagNoData: true,
            tokenvalid: false,
            msgTitleNoData:"Token Tidak Valid",
            msgNoData: "Token tidak ditemukan atau pesan sudah expired."
        })
    }
}

  getTheme = async () => {
    try {
      //const json = await executeGet(TITIK_BACA_GET_THEMES + alias);
      if (window.localStorage.getItem('themes') != null) {
        const ljson = window.localStorage.getItem('themes');
        const json = JSON.parse(ljson)
        if (json.code == 200) {
          if (json.warna!="") {
            this.setState({
              color: json.warna
            });
          }
          if (json.dark!="") {
            this.setState({
              darkcolor: json.dark
            });
          }
          if (json.text!="") {
            this.setState({
              textcolor: json.text
            });
          }
          if (json.head!="") {
            this.setState({
              imgHead: json.head
            });
          }
          if (json.splash!="") {
            this.setState({
              imgSplash: json.splash
            });
          }
        }
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      let long = Number(position.coords.longitude)
      let lat = Number(position.coords.latitude);
      let alt = position.coords.altitude;
      this.setState({
        lat: lat,
        long: long,
        alt: alt
      })
      this.locationInit(lat, long, alt, '0')
    })
  }

  loadData = async (lat, long, alt, tipe) => {
    console.log("TIP: " + tipe);
    console.log("STT: " + this.state.tipe)
    if (tipe!=this.state.tipe) {
        this.setState({
            tipe:tipe,
            dataTop:[]
        });
        
    }
    
    if (tipe == '5') {
      
      this.getLiterasiKeluarga(lat, long, alt,'CAT010')
      this.setState({
        judul: 'Buku Pra Nikah'
      })
    } else if (tipe == '6') {
      this.getLiterasiKeluarga(lat, long, alt,'CAT011')
      this.setState({
        judul: 'Buku Menikah'
      })
    } else if (tipe == '7') {
      this.getLiterasiKeluarga(lat, long, alt,'CAT012')
      this.setState({
        judul: 'Buku Golden Age'
      })
    } else  {
      this.getLiterasiKeluarga(lat, long, alt,'CAT012')
      this.setState({
        judul: 'Literasi Keluarga'
      })
    }

  }

  btnCari = async () => {
    
    if (this.state.tokenvalid==true) {
      this.setState({
        mode: 0,
        hal: 1,
        dataTop: [],
        isLoading: true
      })
      this.searchBuku();
    }
  }

  btnNext = async (lat, long, alt) => {
    
    let mode = this.state.mode
    console.log(mode);
    if (mode == 0) {
      if (this.state.tokenvalid==true) { 
        this.cariBuku()
      }
    } else {
      this.loadData(lat, long, alt, this.state.tipe)
    }
  }

  searchBuku = async () => {
    try {
      let ldata = this.state.dataTop
      let hal = parseInt(this.state.hal)
      let formData = new FormData()
      formData.append('latitude', this.state.lat)
      formData.append('longitude', this.state.long)
      formData.append('isMobile', '1')
      formData.append('search', this.state.search)
      formData.append('tipe', this.props.params.tipe)
      formData.append('token', this.props.params.token)
      formData.append('hal', '1')
      const json = await executePost(TITIK_BACA + this.state.alias + '/pencarian', formData)
      if (json.code == 200) {
        if (json.data.length > 0) {
          if (json.data.length <= 6) {
            this.setState({
              flagMoreSearch: true
            })
          }
          this.setState({
            dataTop: json.data,
            flagData: true,
            flagNoData: false,
            isLoading: false,
            hal: hal + 1
          })
        } else {
          this.setState({
            dataTop: json.data,
            flagData: false,
            flagNoData: true
          })
        }
      } else {
        this.setState({
            isLoading: false,
            flagNoData: true,
            msgTitleNoData:"Token Tidak Valid",
            msgNoData: "Token tidak ditemukan atau pesan sudah expired."
        })
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  cariBuku = async () => {
    try {
      let ldata = this.state.dataTop
      let hal = parseInt(this.state.hal)
      let formData = new FormData()
      formData.append('latitude', this.state.lat)
      formData.append('longitude', this.state.long)
      formData.append('isMobile', '1')
      formData.append('search', this.state.search)
      formData.append('tipe', this.props.params.tipe)
      formData.append('token', this.props.params.token)
      formData.append('hal', hal)
      const json = await executePost(TITIK_BACA + this.state.alias + '/searchingFromIndex', formData)
      if (json.code == 200) {
        if (json.data.length > 0) {
          this.setState({
            dataTop: ldata.concat(json.data),
            flagData: true,
            flagNoData: false,
            isLoading: false,
            hal: hal + 1
          })
        } else {
          this.setState({
            dataTop: json.data,
            flagData: false,
            flagNoData: true,
            isLoading: false
          })
        }
      } else {
        this.setState({
            isLoading: false,
            flagNoData: true,
            msgTitleNoData:"Token Tidak Valid",
            msgNoData: "Token tidak ditemukan atau pesan sudah expired."
        })
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getLiterasiKeluarga = async (lat, long, alt, code) => {
    try {
      let ldata = []//this.state.dataTop
      console.log(this.state.tipe)
      
      console.log("LITERASI KELUARGA");
      console.log(ldata);
      let hal = parseInt(this.state.hal)
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt);
      formData.append('isMobile', '1')
      formData.append('hal', hal)
      formData.append('tokensms', this.state.tokensms)
      console.log(TITIK_BACA_CONTENT_CATEGORIES  + code + '/' + this.state.alias);
      const json = await executePost(TITIK_BACA_CONTENT_CATEGORIES  + code + '/' + window.localStorage.getItem('alias'), formData)
      console.log(json);
      if (json.code == "404") {
        window.location.href = 'https://titikbaca.id'
      } else if (json.code="200") {
        this.setState({
          dataTop: ldata.concat(json.data),
          flagData: true,
          isLoading: false,
          hal: hal + 1
        })
      } else {
        this.setState ( {
          isLoading: false,
          flagData: false
        })
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
    //this.getBukuTop(lat, long, alt)
  }

  

  gotoDetail = async (id, tipe) => {
    let aid = id.split("-");
    id = aid[aid.length-1];
    window.location.href = '/detailKeluarga/konten/' + id + '/' + this.state.alias + '/' + this.state.tokensms
  }

  render() {
    return (
      <>
      
        {this.state.isLoading && <SkeletonSearch />}
        
          
          <div className={"fixed top-0 w-full shadow-md mb-3 p-3 " + this.state.color }>
            <div className="flex items-center">
              <div className="flex-0 mr-4 cursor-pointer hover:bg-green rounded-full p-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 6L9 12L15 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div className="flex-1">
                <div className="relative">
                  <input 
                  onChange={(event) => {
                    this.setState({ search: event.target.value })
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      this.btnCari()
                    }
                  }}
                  type="text" class="flex items-center w-full pr-4 pl-4 py-2.5 bg-white bg-opacity-10 text-white rounded-2xl placeholder:text-gray-400 text-base focus:outline-none focus:border-primary focus:ring-primary" placeholder="Cari buku..." />
                  <button onClick={()=>this.btnCari()} className="absolute right-0 top-0 z-[2] text-sm flex items-center bg-green font-medium leading-tight text-white transition duration-150 ease-in-out hover:bg-[#12b759] p-3 rounded-2xl focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg" type="button" id="button-addon1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 mr-1">
                      <path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clip-rule="evenodd" />
                    </svg>
                    Cari
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 mt-20 bg-white">
            <div className="p-4 mt-4 bg-gray-200 rounded-lg">
              <img src={logoLiterasiKeluarga} alt="logo literasi keluarga" className="mx-auto w-[50%]" />
            </div>
          {/* 
          <div className="flex justify-between items-center px-4 mt-2 line-clamp-1">
            <h3 className="text-base text-black font-semibold">{this.state.judul}</h3>
          </div>
          */}
          {/* {this.state.isLoading && <SkeletonSearch />} */}
          {this.state.flagData && (
            <>
                
                {/* Section Literasi Keluarga */}
                
                  <ul className="bg-white sticky top-16 mt-4 mb-5 flex list-none flex-row flex-wrap border-b-0 pl-0 rounded-lg" role="tablist" data-te-nav-ref>
                    <li role="presentation" className="flex-grow basis-0 text-center">
                      <a href="#tabs-pranikah" onClick={()=>this.loadData (this.state.lat, this.state.long, this.state.alt, '5')} className="block border-x-0 border-b-4 border-t-0 border-transparent px-4 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-y-sky-600 data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400" data-te-toggle="pill" data-te-target="#tabs-pranikah" data-te-nav-active role="tab" aria-controls="tabs-pranikah" aria-selected="true">Pra Nikah</a>
                    </li>
                    <li role="presentation" className="flex-grow basis-0 text-center">
                      <a href="#tabs-menikah" onClick={()=>this.loadData (this.state.lat, this.state.long, this.state.alt, '6')}  className="block border-x-0 border-b-4 border-t-0 border-transparent px-4 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-y-sky-600 data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400" data-te-toggle="pill" data-te-target="#tabs-menikah" role="tab" aria-controls="tabs-menikah" aria-selected="false">Menikah</a>
                    </li>
                    <li role="presentation" className="flex-grow basis-0 text-center">
                      <a href="#tabs-goldenage" onClick={()=>this.loadData (this.state.lat, this.state.long, this.state.alt, '7')}  className="block border-x-0 border-b-4 border-t-0 border-transparent px-4 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-y-sky-600 data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400" data-te-toggle="pill" data-te-target="#tabs-goldenage" role="tab" aria-controls="tabs-goldenage" aria-selected="false">Golden Age</a>
                    </li>
                  </ul>
                <Masonry
                  breakpointCols={{ default: 6, 680: 2}}
                  className="flex w-auto"
                  >

                  {this.state.dataTop.map((item, i) => {
                    return (
                      <div key={i} onClick={() => this.gotoDetail(item.alias, item.tipe)} className="m-2 mb-4 break-inside-avoid rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                        
                        <div className="h-[130px] overflow-hidden">
                          <LazyLoad  className="w-full rounded-xl">
                          <img src={item.cover_file} alt="cover buku" className="w-full rounded-xl" />
                          </LazyLoad>
                        </div>
                        <div className="p-2">
                          <div className="title-book text-sm text-black font-semibold line-clamp-3">{item.judul}</div>
                          <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                          <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                          <div className={"stock mt-1 text-sm " + this.state.textcolor + " font-semibold"}>
                            Stok: {item.terpakai}/{item.qty}
                          </div>
                        </div>
                      </div>
                    )}
                  )}

                </Masonry>
                  
                
              
              {!this.state.flagMoreSearch && (
                <div className="text-center mb-16 h-[88px]">
                  <button type="button" onClick={() => this.btnNext(this.state.lat, this.state.long, this.state.alt)} className={"mt-2 py-2 px-8 border-[1px] border-orange " + this.state.textcolor + " text-sm font-semibold text-center rounded-full"}>
                    Lihat Lebih Banyak
                  </button>
                </div>
              )}
            </>
          )}
          {this.state.flagNoData && (
            <>

              <div className="pt-4 mb-4">
                <svg width="204" height="168" viewBox="0 0 204 168" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                  <path d="M7.51514 77.2217V78.9444" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M3.64648 78.1548L5.05412 79.6039" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M2 82.0122H3.99038" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M3.48535 85.9381L4.89299 84.4891" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M7.51514 86.4091V84.6865" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M10.3862 85.2607L9.23779 84.1123" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M12.6831 82.3896H10.9604" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M11.5347 78.3702L10.3862 79.5186" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M99.9629 2V3.14842" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M96.5181 5.44528H97.6665" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M99.9629 8.89055V7.74213" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M102.834 5.44528H101.686" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M33.3545 50.2338V51.9564" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M28.1865 55.4017H29.9092" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M33.3545 59.9954V58.2728" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M37.9482 55.4017H36.2256" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path d="M156.956 112.845C158.329 112.845 159.442 113.959 159.442 115.331C159.442 116.704 158.329 117.817 156.956 117.817C155.584 117.817 154.471 116.704 154.471 115.331" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M168.33 90.2928C169.806 89.8325 172.068 88.0279 172.591 86.0862C173.066 87.8026 175.056 89.8325 176.852 89.9861C174.831 90.7019 172.898 92.8284 172.591 94.4989C172.383 92.7962 169.709 90.541 168.33 90.2928Z" fill="#EBECEE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M16.8594 112.08C17.5974 111.849 18.7279 110.947 18.9897 109.977C19.2271 110.834 20.222 111.849 21.12 111.927C20.1094 112.284 19.1432 113.347 18.9897 114.183C18.8853 113.331 17.5487 112.203 16.8594 112.08Z" fill="#EBECEE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M30.5557 32.2019C30.5557 33.2668 29.6928 34.1294 28.6287 34.1294C27.564 34.1294 26.7017 33.2668 26.7017 32.2019C26.7017 31.1375 27.564 30.2744 28.6287 30.2744C29.6928 30.2744 30.5557 31.1375 30.5557 32.2019Z" fill="#D1D8DF" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M159.233 37.3776C159.233 39.1569 157.791 40.5992 156.012 40.5992C154.233 40.5992 152.791 39.1569 152.791 37.3776C152.791 35.5983 154.233 34.1559 156.012 34.1559C157.791 34.1559 159.233 35.5983 159.233 37.3776Z" fill="#EBECEE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M203.847 28.649C203.847 30.0606 202.703 31.2045 201.293 31.2045C199.881 31.2045 198.738 30.0606 198.738 28.649C198.738 27.2373 199.881 26.0934 201.293 26.0934C202.703 26.0934 203.847 27.2373 203.847 28.649Z" fill="#EBECEE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M66.5694 148.004C66.5694 148.893 65.8484 149.615 64.9588 149.615C64.0696 149.615 63.3486 148.893 63.3486 148.004C63.3486 147.114 64.0696 146.392 64.9588 146.392C65.8484 146.392 66.5694 147.114 66.5694 148.004Z" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M65.6114 19.8188C65.6114 20.8837 64.7485 21.7463 63.6843 21.7463C62.6202 21.7463 61.7573 20.8837 61.7573 19.8188C61.7573 18.7543 62.6202 17.8913 63.6843 17.8913C64.7485 17.8913 65.6114 18.7543 65.6114 19.8188Z" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M114.766 165.843C113.534 166.312 112.156 165.693 111.688 164.462C111.219 163.23 111.837 161.851 113.069 161.383C114.3 160.915 115.678 161.533 116.147 162.765C116.616 163.996 115.997 165.375 114.766 165.843Z" stroke="#D2D8DF" strokeWidth="2.921" stroke-linecap="round" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M114.032 120.288H66.9442C63.6299 120.288 60.917 117.567 60.917 114.241V47.0914C60.917 43.766 63.6299 41.0464 66.9442 41.0464H114.032C117.348 41.0464 120.061 43.766 120.061 47.0914V114.241C120.061 117.567 117.348 120.288 114.032 120.288Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M110.013 123.159H62.9247C59.6104 123.159 56.8975 120.438 56.8975 117.112V49.9625C56.8975 46.637 59.6104 43.9174 62.9247 43.9174H110.013C113.328 43.9174 116.041 46.637 116.041 49.9625V117.112C116.041 120.438 113.328 123.159 110.013 123.159Z" fill="#E8EBF2" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M106.103 125.425H58.9176C55.5965 125.425 52.8779 122.716 52.8779 119.404V52.5321C52.8779 49.2204 55.5965 46.5121 58.9176 46.5121H106.103C109.425 46.5121 112.144 49.2204 112.144 52.5321V119.404C112.144 122.716 109.425 125.425 106.103 125.425Z" fill="#D8DBEA" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M106.103 125.425H58.9176C55.5965 125.425 52.8779 122.716 52.8779 119.404V52.5321C52.8779 49.2204 55.5965 46.5121 58.9176 46.5121H106.103C109.425 46.5121 112.144 49.2204 112.144 52.5321V119.404C112.144 122.716 109.425 125.425 106.103 125.425Z" fill="#F1F2F7" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M99.3018 97.8934H64.4497C63.4544 97.8934 62.6396 97.1183 62.6396 96.1714C62.6396 95.2232 63.4544 94.4481 64.4497 94.4481H99.3018C100.297 94.4481 101.112 95.2232 101.112 96.1714C101.112 97.1183 100.297 97.8934 99.3018 97.8934Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M82.6419 108.229H64.4574C63.4578 108.229 62.6396 107.454 62.6396 106.507C62.6396 105.559 63.4578 104.784 64.4574 104.784H82.6419C83.6415 104.784 84.4597 105.559 84.4597 106.507C84.4597 107.454 83.6415 108.229 82.6419 108.229Z" fill="#E0E2EE" />
                  <rect x="62.8667" y="55.4017" width="38.4722" height="31.0074" rx="9" fill="#E7E9F5" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M119.346 94.8712C112.377 101.839 101.588 102.625 93.7551 97.2454C92.754 96.5456 91.8013 95.7595 90.9128 94.8712C90.4554 94.4153 90.0243 93.9448 89.6181 93.4495C88.8056 92.4604 88.0939 91.4055 87.5108 90.3155C86.4835 88.4746 85.785 86.5211 85.4036 84.5019C84.1732 78.1697 86.013 71.3538 90.9128 66.4563C95.8243 61.5441 102.641 59.7163 108.975 60.9349C110.993 61.3279 112.948 62.0263 114.789 63.0417C115.881 63.6378 116.921 64.3494 117.912 65.1617C118.406 65.5665 118.877 65.9989 119.333 66.4563C120.222 67.3446 121.02 68.2957 121.707 69.298C127.089 77.1279 126.302 87.9165 119.346 94.8712Z" fill="white" fill-opacity="0.1" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M117.396 93.4988C110.145 100.751 98.3954 100.749 91.1437 93.4988C83.9015 86.2566 83.9015 74.5055 91.1532 67.2551C98.3954 60.0129 110.145 60.0129 117.387 67.2551C124.638 74.5055 124.638 86.2566 117.396 93.4988ZM121.116 63.5299C111.81 54.2235 96.7292 54.2235 87.4228 63.5299C78.1177 72.835 78.1096 87.9253 87.4147 97.2318C95.8847 105.699 109.16 106.463 118.498 99.5105C119.41 98.8305 120.288 98.0682 121.126 97.2318C121.962 96.3939 122.725 95.5156 123.403 94.6036C130.355 85.2648 129.585 71.9986 121.116 63.5299Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M157.742 132.313L157.351 132.703C154.766 135.289 150.533 135.289 147.947 132.703L124.08 108.836L133.875 99.0418L157.742 122.909C160.328 125.495 160.328 129.726 157.742 132.313Z" fill="url(#paint0_linear_107_235)" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M123.918 93.8739L130.971 100.927L125.964 105.932L118.912 98.8795C119.843 98.1857 120.738 97.4078 121.594 96.5543C122.447 95.7007 123.225 94.8045 123.918 93.8739Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M157.384 122.615L147.652 132.346L145.9 130.593L155.631 120.862L157.384 122.615Z" fill="#EF545F" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M134.416 99.3788L124.417 109.378L122.932 107.892L132.93 97.8934L134.416 99.3788Z" fill="url(#paint1_linear_107_235)" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M112.596 64.7606L88.075 89.2802C87.1516 87.6252 86.5237 85.869 86.1821 84.0537L107.369 62.8665C109.183 63.2211 110.941 63.8477 112.596 64.7606Z" fill="white" fill-opacity="0.5" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M120.635 70.5887L94.1252 95.5965C93.1758 94.9715 92.2722 94.2681 91.4296 93.4732C90.9958 93.064 90.5856 92.6443 90.2017 92.201L117.034 66.8859C117.504 67.2494 117.95 67.6364 118.383 68.0456C119.225 68.8406 119.984 69.6917 120.635 70.5887Z" fill="white" fill-opacity="0.5" />
                  <defs>
                    <linearGradient id="paint0_linear_107_235" x1="141.881" y1="81.2412" x2="106.28" y2="116.842" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#FF8960" />
                      <stop offset="1" stopColor="#FF62A5" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_107_235" x1="128.674" y1="92.1513" x2="117.19" y2="103.635" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#FF404E" />
                      <stop offset="1" stopColor="#FF62A5" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="px-8 mt-6 mb-6 text-center">
                  <h3 className="text-black text-lg font-semibold">{this.state.msgTitleNoData}</h3>
                  <p className="mt-4 text-sm text-gray-light">{this.state.msgNoData}</p>
                </div>
              </div>
            </>
          )}
          
        </div>
      </>
      
    )
  }
}

export default WithNavigate
