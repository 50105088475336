import React, { Component } from 'react'
import { goBack, executePost, executeGet } from '../functions/Kubuku'
import { BASE_URL, TITIK_BACA, TITIK_BACA_CEKLOKASI } from '../api'
import Glider from 'react-glider'
import 'glider-js/glider.min.css'
import SkeletonBookDetail from '../components/SkeletonBookDetail'
import QRCode from "react-qr-code";
import logoLiterasiKeluarga from "../assets/images/logo_literasikeluarga.png";

import { useParams, useNavigate } from 'react-router-dom'
import image_location from '../assets/images/location.png'
import bgBottom from '../assets/images/bg-bottom.svg'
import pnri from "../assets/images/cover_pnri.png";
import kemenkes from "../assets/images/kemenkes.png";
import Masonry from 'react-masonry-css'
import LazyLoad from 'react-lazy-load';
import indonesia from '../assets/images/indonesia.png';
import kominfo from "../assets/images/kominfo.png";

function WithNavigate(props) {
  let navigate = useNavigate()
  return <Kementrian {...props} navigate={navigate} params={useParams()} />
}

class Kementrian extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isModal: false, //popup loading
      showMain: false,
      params: this.props.params,
      data: '',
      datar: '',
      dataRekomendasi: '',
      dataPenyumbang: '',
      dataterdekat:'',
      alias: this.props.params.alias,//window.localStorage.getItem('alias'), //this.props.params.alias,
      lokasi: '',
      lat: '-7.557130246009252',
      long: '110.79448368501073',
      alt: '1',
      flagData: false,
      isError: false,
      errorMessage: '',
      msgJauh: 'Maaf saat ini anda sudah diluar jangkauan, silahkan pilih titik baca di sekitar anda.',
      clamp: 'line-clamp-6',
      more: 'Selengkapnya',
      urlbuku: '',
      hdr: '',
      color: window.localStorage.getItem('color'),
      darkcolor: 'bg-orang-dark',
      textcolor: 'text-orange',
      imgHead: '',
      imgSplash: '',
      nomoKTP: '',
      pranikah: false,
      menikah: false,
      goldenage: false
    }
  }

  componentDidMount = async () => {
    
    this.setState({
      alias: this.props.params.alias //window.localStorage.getItem('alias')
    })

  }

  getTheme = async () => {
    try {
      //const json = await executeGet(TITIK_BACA_GET_THEMES + alias);
      if (window.localStorage.getItem('themes') != null) {
        const ljson = window.localStorage.getItem('themes');
        const json = JSON.parse(ljson)
        if (json.code == 200) {
          if (json.warna!="") {
            this.setState({
              color: json.warna
            });
          }
          if (json.dark!="") {
            this.setState({
              darkcolor: json.dark
            });
          }
          if (json.text!="") {
            this.setState({
              textcolor: json.text
            });
          }
          if (json.head!="") {
            this.setState({
              imgHead: json.head
            });
          }
          if (json.splash!="") {
            this.setState({
              imgSplash: json.splash
            });
          }
        }
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  
  gotoListKementerian = async (id) => {
    //if (id=="2") {
    //    window.location.href = "https://literasidigital.id/buku"
    //} else {
        window.location.href = '/listkementerian/' + id + '/' + this.state.alias
    //}
  }

  gotoHome = () => {
    window.location.href = window.location.origin + '/home' + '/' + this.state.alias
  }
  gotoSearch = async (tipe) => {
    window.location.href = '/listBuku/' + tipe + '/' + this.state.alias
  }
  
  render() {
    return (
      <>
        <div>
          
          <div className="top-0">
            <div style={{marginBottom:"8px", position: "relative", maxHeight:"360px"}}>
                
                <img src={indonesia} style={{width:"100vw", borderRadius: "0px"}}>
                </img>
                <div onClick={() => this.gotoHome()} className={"flex p-4  text-lg text-white font-semibold items-center"}  style={{position:"absolute", top:"5px", left:"5px"}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-8">
                    <path d="M15 6L9 12L15 18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div class="w-11/12 text-white text-lg sm:text-3xl lg:text-4xl text-left font-bold" style={{position:"absolute", bottom:"8px", left:"16px"}}>Kementerian / Lembaga</div>
            </div>
            
            <div className="px-4">
                <Masonry
                  breakpointCols={{ default: 6, 680: 2}}
                  className="flex w-auto"
                  >
                    <div  onClick={() => this.gotoSearch(11)} className="m-2 mb-4 break-inside-avoid rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                        <div className="h-[130px] overflow-hidden">
                          <LazyLoad className="w-full rounded-xl">
                          <img src={pnri} alt="cover buku" className="w-full rounded-xl" />
                          </LazyLoad>
                        </div>
                        <div className="p-2">
                            <div className="author mt-1 text-xs text-gray-light line-clamp-1">Koleksi</div>
                            <div className="title-book text-sm text-black font-semibold line-clamp-3">Perpustakaan Nasional RI</div>
                        </div>
                    </div>
                    <div onClick={() => this.gotoListKementerian(1)} className="m-2 mb-4 break-inside-avoid rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                        <div className="h-[130px] overflow-hidden">
                          <LazyLoad className="w-full rounded-xl">
                          <img src={kemenkes} alt="cover buku" className="w-full rounded-xl" />
                          </LazyLoad>
                        </div>
                        <div className="p-2">
                            <div className="author mt-1 text-xs text-gray-light line-clamp-1">Koleksi</div>
                            <div className="title-book text-sm text-black font-semibold line-clamp-3">Kementerian Kesehatan RI</div>
                        </div>
                    </div>
                    <div onClick={() => this.gotoListKementerian(2)} className="m-2 mb-4 break-inside-avoid rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                        <div className="h-[130px] overflow-hidden">
                          <LazyLoad className="w-full rounded-xl">
                          <img src={kominfo} alt="cover buku" className="w-full rounded-xl" />
                          </LazyLoad>
                        </div>
                        <div className="p-2">
                            <div className="author mt-1 text-xs text-gray-light line-clamp-1">Koleksi</div>
                            <div className="title-book text-sm text-black font-semibold line-clamp-3">Kementerian Komunikasi dan Informatika</div>
                        </div>
                    </div>
                </Masonry>
                  
            </div>
          </div>
        </div>
        
      </>
    )
  }
}

export default WithNavigate
