import React, { Component } from 'react'
import { goBack, executePost, executeGet, formatTanggal } from '../functions/Kubuku'
import { TITIK_BACA, TITIK_BACA_BERITA } from '../api'
import Glider from 'react-glider'
import 'glider-js/glider.min.css'
import parse from 'html-react-parser' 

import ModalLoading from '../components/ModalLoading'

import { useParams, useNavigate } from 'react-router-dom'

function WithNavigate(props) {
  let navigate = useNavigate()
  return <MiniBrowser {...props} navigate={navigate} params={useParams()} />
}



class MiniBrowser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      showMain: false,
      params: this.props.params,
      id: this.props.params.id,
      kementerian: this.props.params.kementerian,
      data: '',
      alias: this.props.params.alias,
      lokasi: '',
      flagData: false,
      flagBerita: false,
      urlGames: '',
      color: window.localStorage.getItem('color'),
      bottom: "bottom-20",
      whidden: '',
      tap:0,
      ifr: "block",
      url: atob(this.props.params.url)
    }
  }
  tapHandler = () => {
    this.setState({
      whidden: 'hidden'
    })
    }
  componentDidMount = () => {
    //this.getRSS();
    //document.domain = 'ayosehat.kemkes.go.id'
    if (this.state.kementerian=="1") {
        if (this.state.id=="4") {
        this.setState({urlGames: 'https://kubuku.id/api/konten/devel/index.php?url=https://bintangpusnas.perpusnas.go.id/images/posyandu.pdf'})
        } else if (this.state.id=="5") {
        this.setState({urlGames: 'https://kubuku.id/api/konten/devel/index.php?url=https://bintangpusnas.perpusnas.go.id/images/perakapan_kader.pdf'})
        }  else if (this.state.id=="6") {
            this.setState({urlGames: 'https://kubuku.id/api/konten/devel/index.php?url=https://bintangpusnas.perpusnas.go.id/images/kelas_hamil.pdf'})
        }
    } else if (this.props.params.kementerian == "2") {
        this.setState({urlGames: this.state.url, whidden: "hidden"})
        
    }
  }

  hideDive = () => {
    window.top.document.getElementsByClassName('backdrop-blur-sm')[0].style.display= "none"
  }

  render() {
    return (
      <div>
        
        <nav className="fixed top-0 w-full z-10 shadow-md">
          <div onClick={() => goBack()} className={"flex p-4 " + this.state.color + " text-lg text-white font-semibold items-center"}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-8">
              <path d="M15 6L9 12L15 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            
          </div>
        </nav>
        <div className="mt-10" style={{height: 'calc(100vh - 10px' }}>
            <div style={{height: "100vh"}}>
                <iframe onLoad={()=>this.hideDive()} src={this.state.urlGames} className="w-full h-full"></iframe>
            </div>
            <div onTouchStart={()=>this.tapHandler()} className={this.state.whidden + ' mr-10 ml-9 p-2 fixed z-200 ' + this.state.bottom + ' border rounded-lg items-center w-10/12 text-sm text-center'} style={{borderColor:"rgba(252, 106, 48, 0.43)" , backgroundColor:"rgba(251, 184, 157, 0.43)"}}>Untuk membaca lebih jelas, lakukan double tap layar.</div>
        </div>
        
      </div>
    )
  }
}

export default WithNavigate
