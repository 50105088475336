import React, { Component, useState } from 'react';
import { goBack } from '../functions/Kubuku';
import { QrReader } from 'react-qr-reader';

const QR = (props) => {
  const [data, setData] = useState('No result');
  const previewStyle = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  }
  const camStyle = {
    display: 'flex',
    justifyContent: 'center'
  }
  return (
    <>
      <QrReader
        onResult={(result, error) => {
          if (!!result) {
            if (result?.text.indexOf("https://buka.titikbaca.id")>=0) {
              setData(result?.text);
              console.log(result?.text)
              window.location.href = result?.text;
            }
          }

          if (!!error) {
            //console.info(error);
          }
        }}
        videoStyle={previewStyle}
        videoContainerStyle={camStyle}
        constraints={{
          facingMode: "environment"
        }}
      />
    </>
  );
};

class QRScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: window.location.pathname,
      url: ''
    }

  }

  componentDidMount = async () => {
    //this.loadRSS();
  };


  render() {
    return (
      <div>
        <nav className="fixed top-0 w-full z-10 shadow-md">
          <div onClick={() => goBack()} className="flex p-4 bg-orange text-lg text-white font-semibold items-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-8">
              <path d="M15 6L9 12L15 18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            QR Scanner
          </div>
        </nav>
        <div className='mx-2 mt-10 p-2'>
          <div className='mx-4 mt-8'>
            <QR />
          </div>
          <center className='text-xl'><h3>Arahkan kamera ke QR Code Titikbaca</h3></center>
        </div>
      </div>
    );
  }
}

export default QRScanner
