/*
import LogoHeader from '../assets/logo_header_walang.svg'; //tokobukupintar.com
import logoWalang from '../assets/logo_toko_putih.svg'; //tokobukupintar.com

var AppName = "Toko Buku Pintar";
var clientID = "STR0107052022";


/*
import LogoHeader from '../assets/LOGO.png'; //tokobukupintar.com
import logoWalang from '../assets/LOGO_PUTIH.png'; //tokobukupintar.com

var AppName = "Graha Ilmu Digital";
var clientID = "grahailmudigital";
*/

var clientSettings = {

    appName: localStorage.getItem("namaToko"),
    clientId: localStorage.getItem("kodeApp"),
    logo: localStorage.getItem("logo"),
    logoputih: localStorage.getItem("logo_putih"),
    os: 'web',
    ostype: 'desktop',
    version: '1'
}

const createNewHeaders = (token, data) => {
    //localStorage.setItem("namaToko",this.clientSettings.appName);
    const uuid = localStorage.getItem('uuid');
    const beranda = localStorage.getItem("beranda");
    const kode = localStorage.getItem('kode');
    const sn = localStorage.getItem('sn');
    const guid = localStorage.getItem('guid');
    //console.log("create token = " + token);
    window.customHeaders = {
        "CLIENTID": localStorage.getItem("kodeApp"),
        "VERSION": clientSettings.version,
        "UUID": uuid,
        "KODEBERANDA": beranda,
        "UID": kode,
        // "UID": 'USR275BE985C',
        "OS": clientSettings.os,
        "OSTYPE": clientSettings.ostype,
        //"SN" : sn,
        //"GUID" : guid
        // "TOKEN": 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTI1ODc5NTcsImlzcyI6Imt1YnVrdS5jby5pZCIsImF1ZCI6Imt1YnVrdS5jby5pZCIsImRhdGEiOnsiaWRkIjoiVlZOU01qYzFRa1U1T0RWRCIsInV1aWQiOiJNVEl6TkMweE1qTTBMVEV5TXpRdE1USXpOQT09IiwidXNlciI6eyJrb2RlIjoiVVNSMjc1QkU5ODVDIiwiZW1haWwiOiJkYXdhbXN1cnVyaTIxQGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiIiwibmFtYSI6ImRhd2FtIiwiYXZhdGFyIjoiaHR0cHM6XC9cL2t1YnVrdS5teS5pZFwvYXBpXC9pbWFnZXNcL2F2YXRhclwvdXNlci5wbmciLCJha3RpZiI6IjEiLCJ1dWlkIjoiMTIzNC0xMjM0LTEyMzQtMTIzNCIsImNsaWVudElkIjoiU1RSMDEwNzA1MjAyMiIsIm9zIjoid2ViIn19fQ.2XdAxXsg4Waim2YQnH7UES81ibShLTnRKaF_bFtliV4'
    }

}

const updateHeaders = (key, data) => {

    const uuid = localStorage.getItem('uuid');
    const token = localStorage.getItem('token');
    const kode = localStorage.getItem('kode');
    const nama_pengguna = localStorage.getItem("name");
    const email_pengguna = localStorage.getItem("email");
    const id_pengguna = localStorage.getItem("id_pengguna");
    const id_mitra = localStorage.getItem("id_mitra");
    const sn = localStorage.getItem('sn');
    const guid = localStorage.getItem('guid');
    //console.log("update_token = " + token);
    //console.log("update_kode = " + kode);

    window.customHeaders = {
        "CLIENTID": localStorage.getItem("kodeApp"),
        "VERSION": clientSettings.version,
        "UUID": uuid,
        "UID": kode,
        "OS": clientSettings.os,
        "OSTYPE": clientSettings.ostype,
        "TOKEN": token,
        "namapengguna": nama_pengguna,
        "emailpengguna": email_pengguna,
        "idpengguna": id_pengguna,
        "idmitra": id_mitra,
        //"SN":sn,
        //"GUID": guid

    }

}

const executePost = async (url, formData) => {
    updateHeaders();
    /*
    
    let lat = -6.964262
    let long = 110.427928
    let alt = 12.25585150718689
    
    formData.append('latitude', lat)
    formData.append('longitude', long)
    formData.append('altitude', alt)
    */
    try {
        formData.append('uuid', window.localStorage.getItem("uuid"))
        if (window.localStorage.getItem("sn")!=null) {
        formData.append('sn', window.localStorage.getItem("sn"))
        formData.append('guid', window.localStorage.getItem("guid"))
        }
        let postData = {
            method: 'post',
            headers: window.customHeaders,
            //headers: { 'Content-Type': 'application/json' },
            body: formData
        } 

        let cacheName =  "post_cache_" + url.replace("https://kubuku.id/api/titikBaca/","");
        let caching=false;
        // let checking=false;
        // console.log("Try caching ", cacheName);
        // if(cacheName.search("searching")>0){
        // //     // console.log("Gothca ", cacheName);
        // //     checking=true;
        //     caching=false;
        // }




        if(caching==true){
            if(formData.get('uuid'))  cacheName+="_" + formData.get('uuid');
            if(formData.get('alias')) cacheName+="_" + formData.get('alias');
            if(formData.get('kategori'))  cacheName+= "_" + formData.get('kategori');
            if(formData.get('search'))  cacheName+= "_" + formData.get('search');
            if(formData.get('hal'))  cacheName+= "_" + formData.get('hal');
            cacheName= cacheName.replace("/","_");
            const dataCache = window.localStorage.getItem(cacheName);                
            if(dataCache){
                const object = JSON.parse(dataCache);
                let dateString = object.cachetimestamp;
                const now = new Date().getTime().toString();

                // console.log(`Try read cacheName :${cacheName}`); 

                if(object.length>0){
                    // console.log("object[0].cachetimestamp",cacheName, object[0].cachetimestamp);
                    if(object[0].cachetimestamp) {
                        dateString = object[0].cachetimestamp;
                        dateString = dateString + '000'; //.substr(0, dateString.length-3);
                        // console.log("dateString",dateString);
                    }
                }

                // console.log(`cacheName :${cacheName} |  NOW : ${now} |  dateString: ${dateString} | AGE : ${now-dateString} `); 

                if((now-dateString)<864000) {
                    // console.log(`Load Using Cache ${cacheName} : Cache Age 3600000 > ${(now-dateString)}  `); 
                    return object;
                }
                else
                {
                    // console.log(` Cache Age  > ${(now-dateString)}  `); 
                }
            }    
        }


        const response = await fetch(url, postData);
        const json = await response.text();
        //console.log(json);
        const jsonCache = JSON.parse(json);


        if(caching==true){
            if(jsonCache){
                if(jsonCache.code==200){
                    let saveit = true;
                    const now = new Date().getTime().toString();            
                    jsonCache.cachetimestamp=now;    
                    if(jsonCache.data){
                        if(jsonCache.data.length<1){
                            saveit=false;                    
                        }
                        // else if(jsonCache.data.code){
                        //     if(jsonCache.data.code==200){
                        //         saveit=true;                    
                        //     }
                        // }
                    }

                    // console.log("Saving cache ", jsonCache, saveit, cacheName);         
                    if(saveit==true){
                        const data =JSON.stringify(jsonCache);
                        localStorage.setItem(cacheName, data);     
                        // console.log("Saving cache", cacheName, data);           
                        console.log("Saving cache", cacheName);           
                    }
                }
                else
                {
                    if(jsonCache.length==0){ 
                        console.log("NO CODE RESPONSE", cacheName, jsonCache.length);           
                    }
                    const now = new Date().getTime().toString();            
                    jsonCache.cachetimestamp=now;    
                    const data =JSON.stringify(jsonCache);
                    localStorage.setItem(cacheName, data);     
                    // console.log("Saving cache", cacheName, data);           
                    console.log("Saving cache", cacheName);           

                }


            }

        }


        // if(caching==false) {       
        //     console.log("cache false", jsonCache.length);
        //     console.log("cache false", jsonCache);
        // }
        return jsonCache;

    } catch (error) {

        let errorObj = {
            code: 500,
            msg: 'Network Error'
        }
        //console.log(error);
        return errorObj;
    }
}

const executeGet = async (url) => {
    // console.log("header = " + JSON.stringify(window.customHeaders));
    let cacheName =  "get_cache_" + url.replace("https://kubuku.id/api/titikBaca/","");
    let caching=true;

    // console.log(`Checking ${cacheName} Exists....`, dataCache);    


    // cacheName= cacheName.replace('/','_');


    const dataCache = window.localStorage.getItem(cacheName);    
    if(dataCache){
        // console.log(`Checking ${cacheName} Exists....`, dataCache);    
        var object = JSON.parse(dataCache),
        dateString = object.cachetimestamp,
        now = new Date().getTime().toString();
                // console.log(`cacheName :${cacheName} |  NOW : ${now} |  dateString: ${dateString} | AGE : ${now-dateString} `); 

        if((now-dateString)<3600000) {
            // console.log(`GET Using ${cacheName} : Cache Age 3600000 > ${(now-dateString)}  `); 
            return JSON.parse(dataCache);
        }
    }    

    try {
        let postData = {
            method: 'get',
            //headers: window.customHeaders,
        }
        const response = await fetch(url, postData);
        const json = await response.json();
        if(caching==true){
            const now = new Date().getTime().toString();
            json.cachetimestamp=now;
            const data =JSON.stringify(json);
            localStorage.setItem(cacheName, data);     
            console.log("Saving cache", cacheName);           
        }
        return json;

    } catch (error) {
        //console.log(error);
        let errorObj = {
            code: 500,
            msg: 'Network Error'
        }
        return errorObj;
    }
}
const goBack = () =>{
    window.history.back();
}

function formatTanggal(tgl) {
    //const d = new Date("2022-09-17 05:08:07"); 
    const d = new Date(tgl);
    const weekday = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun",
      "Jul", "Agu", "Sep", "Okt", "Nov", "Des"
    ];
  
    let tanggal = weekday[d.getDay()] + ', ' + d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear() + ' ' + d.toLocaleTimeString('en-GB');
    
    return tanggal;
  }
  function shuffle(array) {
    const newArray = [...array]
    const length = newArray.length
  
    for (let start = 0; start < length; start++) {
      const randomPosition = Math.floor((newArray.length - start) * Math.random())
      const randomItem = newArray.splice(randomPosition, 1)
  
      newArray.push(...randomItem)
    }
  
    return newArray
  }
export {shuffle, formatTanggal, goBack, clientSettings, createNewHeaders, executeGet,  executePost, updateHeaders };
