import React, { Component } from 'react'
import ReactAudioPlayer from 'react-audio-player'

import { GET_RADIO } from '../api'
import { executeGet, goBack } from '../functions/Kubuku'
import SkeletonRadioDetail from '../components/SkeletonRadioDetail'
import classNames from 'classnames'

import { useParams, useNavigate } from 'react-router-dom'

function WithNavigate(props) {
  let navigate = useNavigate()
  return <RadioDetail {...props} navigate={navigate} params={useParams()} />
}

class RadioDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      muted: true,
      url: '',
      logo: '',
      nama: '',
      tagline: '',
      putar: false,
      pulse: 'animate-none',
      isLoading: true,
      flagData: false,
      flagDataList: false,
      radioStatus: '',
      data: [],
      jsonRadio: [],
      idRadio: 0
    }
    console.log(this.props)
  }

  componentDidMount = async () => {
    this.getRadio(this.props.params.id)
    //this.getListRadio();
    this.getRandom()
  }

  gotoRadioDetail = async (radio) => {
    this.getRadio(radio.id)
    //window.location.href = '/detailRadio/' + radio.id
  }
  getRandom = () => {
    setInterval(() => {
      let newArray = [...this.state.jsonRadio]
      let length = newArray.length

      for (let start = 0; start < length; start++) {
        const randomPosition = Math.floor((newArray.length - start) * Math.random())
        const randomItem = newArray.splice(randomPosition, 1)
        if (randomItem[0].id != this.state.idRadio) {
          newArray.push(...randomItem)
        }
      }
      this.setState({
        data: newArray
      })
    }, 60000)
  }
  getListRadio = async () => {
    try {
      //this.setState({flagDataList:false});
      const json = await executeGet(GET_RADIO)
      if (json.code == 404) {
        window.location.href = 'https://titikbaca.id'
      } else {
        let newArray = [...json]
        let length = newArray.length

        for (let start = 0; start < length; start++) {
          const randomPosition = Math.floor((newArray.length - start) * Math.random())
          const randomItem = newArray.splice(randomPosition, 1)
          if (randomItem[0].id != this.state.idRadio) {
            newArray.push(...randomItem)
          }
        }
        this.setState({
          jsonRadio: json,
          data: newArray,
          isLoading: false,
          flagDataList: true
        })
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getRadio = async (id) => {
    try {
      const json = await executeGet(GET_RADIO + '/' + id)

      this.setState({
        url: json[0].urlStream,
        logo: json[0].imageRadio,
        nama: json[0].namaChannel,
        tagline: json[0].tagline,
        isLoading: false,
        flagData: true,
        idRadio: id
      })
      if (this.state.muted == false) this.muted()
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
    this.getListRadio()
  }

  muted = () => {
    if (this.state.muted === false) {
      this.setState({
        muted: true,
        putar: false,
        pulse: 'animate-none',
        radioStatus: ''
      })
      document.querySelector('Audio').pause()
    } else {
      document.querySelector('Audio').play()
    }
  }

  play = async () => {
    this.setState({ radioStatus: 'Now Playing', muted: false, putar: true, pulse: 'animate-pulse' }, () => {
      console.log('state putar = ' + this.state.putar)
    })
  }

  render() {
    let pulse = classNames('flex items-center justify-center space-x-2 ', this.state.pulse)
    return (
      <div>
        {this.state.isLoading && <SkeletonRadioDetail />}
        {this.state.flagData && (
          <>
            <div onClick={() => goBack()} className="fixed left-[10px] top-[24px]">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-8">
                <path d="M15 6L9 12L15 18" stroke="#232D42" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <div className="sm:hidden">
              <div className="absolute mx-auto top-0 left-[calc(30vw-10px)] w-44 h-[300px] bg-gradient-to-r from-[#f28e26] to-[#fd644f] rounded-b-full">
                <h3 className="pt-6 text-center text-lg text-white font-semibold">{this.state.radioStatus}</h3>
                <div className="absolute top-[129px] left-[9px] w-[158px] border-4 border-white shadow-md rounded-full mx-auto z-10 overflow-hidden">
                  <img onClick={() => this.muted()} src={this.state.logo} alt={'logo ' + this.state.nama} className={this.state.putar ? 'rounded-full mx-auto animate-spin-slow' : 'rounded-full mx-auto'} />
                </div>
              </div>

              <div className="mt-[330px] text-center">
                <div onClick={() => this.muted()} className="cursor-pointer h-[70px] w-[70px] pt-[18px] mb-4 rounded-full bg-gradient-to-r from-[#f28e26] to-[#fd644f] text-center shadow-lg mx-auto">
                  {this.state.muted ? (
                    <>
                      <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                        <path d="M15.0733 5.20654C13.3412 4.21896 11.9958 3.45183 10.9031 2.95418C9.80638 2.45466 8.85435 2.17257 7.92862 2.26865C6.45941 2.42116 5.1219 3.18242 4.25027 4.36721C3.69972 5.11557 3.47048 6.07608 3.36002 7.26521C3.24999 8.44959 3.25 9.98465 3.25 11.9588V12.0411C3.24999 14.0153 3.24999 15.5504 3.36002 16.7347C3.47048 17.9239 3.69972 18.8844 4.25027 19.6327C5.1219 20.8175 6.45941 21.5788 7.92862 21.7313C8.85435 21.8274 9.80638 21.5453 10.9031 21.0458C11.9958 20.5481 13.3412 19.781 15.0732 18.7934L15.1435 18.7533C16.8755 17.7658 18.2209 16.9987 19.2038 16.3129C20.1894 15.6252 20.916 14.9503 21.297 14.1052C21.901 12.7654 21.901 11.2346 21.297 9.89473C20.916 9.04969 20.1894 8.37477 19.2038 7.68703C18.2209 7.00126 16.8755 6.23412 15.1435 5.24661L15.0733 5.20654Z" fill="#FAFAFA" />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                        <path d="M17.2757 5.47001C17.7109 5.63035 18 6.04498 18 6.50878L18 17.4913C18 17.9551 17.7109 18.3697 17.2757 18.53C16.4523 18.8334 15.5477 18.8334 14.7243 18.53C14.2891 18.3697 14 17.9551 14 17.4913L14 6.50878C14 6.04498 14.2891 5.63035 14.7243 5.47001C15.5477 5.16665 16.4523 5.16665 17.2757 5.47001Z" fill="white" />
                        <path d="M9.27568 5.47001C9.71088 5.63035 10 6.04498 10 6.50878L10 17.4913C10 17.9551 9.71088 18.3697 9.27568 18.53C8.45228 18.8334 7.54772 18.8334 6.72432 18.53C6.28912 18.3697 6 17.9551 6 17.4913L6 6.50878C6 6.04498 6.28912 5.63035 6.72432 5.47001C7.54772 5.16665 8.45228 5.16665 9.27568 5.47001Z" fill="white" />
                      </svg>
                    </>
                  )}
                </div>
                <h3 className="text-xl text-black font-semibold">{this.state.nama}</h3>
                <div className="text-gray-light text-sm mb-4">{this.state.tagline}</div>
              </div>
              <div className="w-full">
                <ReactAudioPlayer
                  src={this.state.url}
                  onPlay={() => this.play()}
                  controls={false}
                  preload="metadata"
                  ref={(element) => {
                    this.rap = element
                  }}
                />
              </div>

              {this.state.flagDataList && (
                <>
                  <div className="fixed bottom-[70px] flex p-2 w-full items-center z-50">
                    <div className="grid grid-cols-4 gap-3">
                      {this.state.data.length > 0 && this.state.flagDataList && (
                        <>
                          {this.state.data.slice(0, 4).map((item) => {
                            return (
                              <div key={item.id} onClick={() => this.gotoRadioDetail(item)} id={item.id} className=" cursor-pointer rounded-xl shadow-md hover:shadow-lg duration-150 ease-out">
                                <div className="overflow-hidden">
                                  <img src={item.imageRadio} alt="logo radio" className="rounded-xl w-full mx-auto" />
                                </div>
                                <div className="p-2">
                                  <div className="title-book text-sm text-black font-semibold line-clamp-1">{item.namaChannel}</div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="hidden sm:block">
              <div className="flex w-screen">
                <div className="absolute w-[50vw] h-[100vh] bg-gradient-to-r from-[#f28e26] to-[#fd644f]">
                  <div className="absolute mt-[12px] ml-[2vw] w-[45vw]">
                    <div className="flex items-center content-center">
                      <div className="w-[70px]">
                        <div onClick={() => this.muted()} className="mr-[20px] cursor-pointer h-[70px] w-[70px]  pt-[16px] mb-4 rounded-full bg-gradient-to-r from-[#f28e26] to-[#fd644f] text-center shadow-lg mx-auto">
                          {this.state.muted ? (
                            <>
                              <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                                <path d="M15.0733 5.20654C13.3412 4.21896 11.9958 3.45183 10.9031 2.95418C9.80638 2.45466 8.85435 2.17257 7.92862 2.26865C6.45941 2.42116 5.1219 3.18242 4.25027 4.36721C3.69972 5.11557 3.47048 6.07608 3.36002 7.26521C3.24999 8.44959 3.25 9.98465 3.25 11.9588V12.0411C3.24999 14.0153 3.24999 15.5504 3.36002 16.7347C3.47048 17.9239 3.69972 18.8844 4.25027 19.6327C5.1219 20.8175 6.45941 21.5788 7.92862 21.7313C8.85435 21.8274 9.80638 21.5453 10.9031 21.0458C11.9958 20.5481 13.3412 19.781 15.0732 18.7934L15.1435 18.7533C16.8755 17.7658 18.2209 16.9987 19.2038 16.3129C20.1894 15.6252 20.916 14.9503 21.297 14.1052C21.901 12.7654 21.901 11.2346 21.297 9.89473C20.916 9.04969 20.1894 8.37477 19.2038 7.68703C18.2209 7.00126 16.8755 6.23412 15.1435 5.24661L15.0733 5.20654Z" fill="#FAFAFA" />
                              </svg>
                            </>
                          ) : (
                            <>
                              <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                                <path d="M17.2757 5.47001C17.7109 5.63035 18 6.04498 18 6.50878L18 17.4913C18 17.9551 17.7109 18.3697 17.2757 18.53C16.4523 18.8334 15.5477 18.8334 14.7243 18.53C14.2891 18.3697 14 17.9551 14 17.4913L14 6.50878C14 6.04498 14.2891 5.63035 14.7243 5.47001C15.5477 5.16665 16.4523 5.16665 17.2757 5.47001Z" fill="white" />
                                <path d="M9.27568 5.47001C9.71088 5.63035 10 6.04498 10 6.50878L10 17.4913C10 17.9551 9.71088 18.3697 9.27568 18.53C8.45228 18.8334 7.54772 18.8334 6.72432 18.53C6.28912 18.3697 6 17.9551 6 17.4913L6 6.50878C6 6.04498 6.28912 5.63035 6.72432 5.47001C7.54772 5.16665 8.45228 5.16665 9.27568 5.47001Z" fill="white" />
                              </svg>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="ml-[20px]">
                        <h3 className="text-xl text-white font-semibold">{this.state.nama}</h3>
                        <div className="text-white text-sm mb-4">{this.state.tagline}</div>
                      </div>
                    </div>
                    <div className="absolute top-[calc(50vh-10vw)] left-[13vw] w-[20vw] border-4 border-white shadow-md rounded-full mx-auto z-10 overflow-hidden">
                      <img onClick={() => this.muted()} src={this.state.logo} alt="logo radio" className={this.state.putar ? 'rounded-full mx-auto animate-spin-slow' : 'rounded-full mx-auto'} />
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <ReactAudioPlayer
                    src={this.state.url}
                    onPlay={() => this.play()}
                    controls={false}
                    preload="metadata"
                    ref={(element) => {
                      this.rap = element
                    }}
                  />
                </div>

                {this.state.flagDataList && (
                  <>
                    <div className="absolute ml-[50vw] w-[50vw] bg-black text-center h-[100vh] p-12">
                      <div className="grid grid-cols-3 gap-3 md:grid-cols-3 md:gap-4">
                        {this.state.data.length > 0 && this.state.flagData && (
                          <>
                            {this.state.data.slice(0, 6).map((item) => {
                              return (
                                <div key={item.id} onClick={() => this.gotoRadioDetail(item)} id={item.id} className=" cursor-pointer rounded-xl shadow-md hover:shadow-lg duration-150 ease-out">
                                  <div className="overflow-hidden">
                                    <img src={item.imageRadio} alt="logo radio" className="rounded-xl w-full mx-auto" />
                                  </div>
                                  <div className="p-2">
                                    <div className="title-book text-sm text-white font-semibold line-clamp-1">{item.namaChannel}</div>
                                  </div>
                                </div>
                              )
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default WithNavigate
